import { isTopBarMode } from "../../../components/utils/deviceMode";
import React from "react";
import { View, useWindowDimensions } from "react-native";
import { JText } from "../../../components";
import useTheme from "../../../theme/ThemeProvider";
import fnStyles from "./../ParamsScreenStyle";

const AccountUnknownBlock = () => {
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const styles = fnStyles(theme, isTopBarMode({ width, height }));
  return (
    <View>
      <JText
        labelKey="auth.unknownedAccount"
        isBold
        centered
        style={styles.noOrgaLabel}
        titleLevel={2}
      />
      <JText labelKey={"auth.unknownedLegende"} centered />
    </View>
  );
};

export default AccountUnknownBlock;
