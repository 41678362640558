import { Platform, StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme, isTopBarMode: boolean) =>
  StyleSheet.create({
    contentContainer: {
      paddingHorizontal: theme.normalize(theme.sizings.medium),
      display: "flex",
      alignItems: "center",
      minHeight: "80%",
      backgroundColor: theme.colors.white,
      paddingBottom: theme.normalize(isTopBarMode ? 30 : 60),
      width: isTopBarMode ? "50%" : "90%",
      alignSelf: "center",
    },
    button: {
      marginVertical: theme.normalize(theme.sizings.mediumLarge),
      marginTop: theme.normalize(theme.sizings.larger),
      width: "70%",
      alignSelf: "center",
    },
    logo: {
      width: theme.normalize(300),
      height: theme.normalize(80),
    },
    divider: {
      width: 60,
      height: 3,
      marginVertical: theme.sizings.mediumLarge,
    },
    findLocationButton: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.colors.primary,
      width: "100%",
      borderRadius: theme.radius.small,
      paddingVertical: theme.sizings.small,
      marginTop: theme.sizings.large,
      marginBottom: theme.sizings.smallMedium,
    },
    findLocationButtonText: {
      marginLeft: theme.sizings.smallMedium,
      marginBottom: Platform.OS === "web" ? 0 : theme.sizings.tiny,
      marginTop: Platform.OS === "web" ? theme.sizings.tiny : 0,
    },
  });
