import React, { useCallback, useMemo } from "react";
import { StackScreenProps } from "@react-navigation/stack";
import { SitesParamsList } from "../../navigation";
import { Linking, Platform, View, Text } from "react-native";
import {
  Card,
  Clickable,
  Divider,
  DocumentItem,
  JText,
  Scrollable,
} from "../../components";
import useTheme from "../../theme/ThemeProvider";
import fnStyles from "./SiteInformationScreenStyle";
import { BoxedLayout } from "components/common/layout/BoxedLayout";
import { isTopBarMode } from "components/utils/deviceMode";
import { SectionIntro } from "./sections/sectionIntro";
import { useWindowDimensions } from "react-native";
import useOrganization from "../../hooks/useOrganization";
import Map from "../../components/jlib/map/Map";
import { zoomLevel } from "../../components/jlib/map/MapStyle";
import useSites from "hooks/useSites";
import getStatusBarHeight from "utils/getStatusBarHeight";
import { JHtml } from "components/jlib/jHtml/JHtml";
import { useTranslation } from "react-i18next";
import getWording from "utils/wording";
import useConfiguration from "../../configuration/ConfigurationProvider";
import { displayPhoneNumber } from "utils/displayPhoneNumber";

type Props = StackScreenProps<SitesParamsList, "siteInformation">;

export const SiteInformationScreen: React.FC<Props> = ({ navigation }) => {
  const { site, setSite, sites } = useSites();
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const { t } = useTranslation();
  const { configuration } = useConfiguration();

  const { organization: currentOrganization } = useOrganization();

  const styles = fnStyles(theme, width, isTopBarMode({ width, height }));

  const onParentClicked = useCallback(() => {
    if (site && site.parent) {
      const parentSite = sites?.find((s) => s.id === site.parent!.id);
      if (parentSite) {
        setSite(parentSite);
        navigation.navigate("siteDetails", {
          siteId: Number(parentSite.id),
        });
      }
    }
  }, [navigation, setSite, site, sites]);

  const sitePoints = useMemo(() => {
    return site && site.children && site.children?.length > 0
      ? site.children.map((c) => ({
          id: c.id,
          lat: c?.geojson?.lat || 0,
          lng: c?.geojson?.lng || 0,
          name: c?.name || "",
          isCustomMarkerSelected: c?.isCustomMarkerSelected,
          customMarkerSelected: c?.customMarkerSelected,
        }))
      : site?.geojson?.lat &&
        site?.siteType?.isGeo &&
        !(currentOrganization && !currentOrganization.activeModuleMap)
      ? {
          id: site.id,
          lat: site?.geojson?.lat || 0,
          lng: site?.geojson?.lng || 0,
          name: site?.name || "",
          isCustomMarkerSelected: site?.isCustomMarkerSelected,
          customMarkerSelected: site?.customMarkerSelected,
        }
      : null;
  }, [currentOrganization, site]);

  const mapInitialRegion = useMemo(() => {
    return Array.isArray(sitePoints)
      ? {
          lat:
            sitePoints.reduce((acc, val) => acc + (val?.lat || 0), 0) /
            sitePoints.length,
          lng:
            sitePoints.reduce((acc, val) => acc + (val?.lng || 0), 0) /
            sitePoints.length,
          id: 1,
        }
      : sitePoints;
  }, [sitePoints]);

  if (!site) {
    return <View />;
  }

  const contents = site?.description?.split("\n");

  return (
    <Scrollable>
      <BoxedLayout
        style={{
          backgroundColor: theme.colors.white,
          marginTop: isTopBarMode({ width, height })
            ? theme.normalize(100)
            : getStatusBarHeight(),
          padding: isTopBarMode({ width, height }) ? theme.sizings.medium : 0,
          borderRadius: theme.radius.medium,
        }}
      >
        {!isTopBarMode({ width, height }) && (
          <>
            <SectionIntro site={site} navigation={navigation} />
            <Divider />
          </>
        )}

        <View style={styles.body}>
          {site?.parent && site?.parent?.id && (
            <Clickable
              onPress={onParentClicked}
              style={{
                marginBottom: theme.normalize(theme.sizings.medium),
                marginTop: isTopBarMode({ width, height })
                  ? 0
                  : theme.sizings.medium,
                alignSelf: "flex-end",
              }}
            >
              <JText
                label={`${t("site.check")} ${site.parent.name} (${t(
                  "site.mainSite",
                  {
                    wording: getWording(configuration).singular,
                  }
                )})`}
                isBold
                sizeName={Platform.OS !== "web" ? "average" : "mediumLarge"}
                titleLevel={3}
                style={{ textDecorationLine: "underline" }}
              />
            </Clickable>
          )}
          {Boolean(contents?.length || site?.descriptionRich) && (
            <View style={styles.section}>
              <JText
                labelKey="site.description"
                isBold
                sizeName={"largeLow"}
                titleLevel={3}
              />
              {site?.descriptionRich ? (
                <JHtml
                  source={site?.descriptionRich}
                  style={styles.richText}
                  //    contentWidth={width}
                />
              ) : (
                contents?.map((content: string, key: number) => (
                  <View key={`content-${key}`}>
                    <JText
                      label={content}
                      sizeName={"mediumLarge"}
                      style={styles.description}
                    />
                  </View>
                ))
              )}
            </View>
          )}

          {site.documents && (
            <>
              <Divider />
              <View style={styles.section} role="list">
                <JText
                  labelKey="site.documents"
                  isBold
                  sizeName={"largeLow"}
                  titleLevel={3}
                />
                {site.documents.map((document, index) => {
                  return (
                    <DocumentItem
                      document={document}
                      key={index}
                      subject={site?.name}
                    />
                  );
                })}
              </View>
            </>
          )}

          {sitePoints && (
            <>
              <Divider />
              <View style={styles.map}>
                <Map
                  height={
                    isTopBarMode({ width, height }) ? width * 0.4 : width * 0.8
                  }
                  key={`map-${Array.isArray(sitePoints)}`}
                  points={Array.isArray(sitePoints) ? sitePoints : [sitePoints]}
                  zoomLvl={
                    Array.isArray(sitePoints) ? undefined : zoomLevel.XHUGE
                  }
                  initialRegion={mapInitialRegion}
                  scrollEnabled={false}
                />
              </View>
            </>
          )}

          {site.adminContact && (
            <Card>
              <JText
                labelKey={"site.contactProfile"}
                isBold
                style={{ marginBottom: theme.normalize(4) }}
              />
              <View style={styles.contactRow}>
                <JText isBold>Nom&nbsp;: </JText>
                <JText label={site?.adminContact.name} />
              </View>
              {site.adminContact.email && (
                <View style={styles.contactRow}>
                  <JText isBold>Email&nbsp;: </JText>
                  <Clickable
                    style={{
                      flexShrink: 1,
                    }}
                    onPress={() =>
                      Linking.openURL(`mailto:${site?.adminContact?.email}`)
                    }
                  >
                    <JText
                      label={site?.adminContact?.email}
                      style={{
                        color: theme.colors.infoDark,
                        textDecorationLine: "underline",
                        flexWrap: "wrap",
                        flex: 1,
                      }}
                    />
                  </Clickable>
                </View>
              )}
              {site.adminContact.phoneNumber && (
                <View style={styles.contactRow}>
                  <JText isBold>Téléphone&nbsp;: </JText>
                  <Clickable
                    style={{
                      alignItems: "center",
                    }}
                    onPress={() =>
                      Linking.openURL(`tel:${site?.adminContact?.phoneNumber}`)
                    }
                  >
                    <JText
                      label={displayPhoneNumber(site?.adminContact.phoneNumber)}
                      style={{
                        color: theme.colors.infoDark,
                        textDecorationLine: "underline",
                      }}
                    />
                  </Clickable>
                </View>
              )}
            </Card>
          )}
        </View>
      </BoxedLayout>
    </Scrollable>
  );
};
