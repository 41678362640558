import { StackScreenProps } from "@react-navigation/stack";
import { BoxedLayout } from "components/common/layout/BoxedLayout";
import { isTopBarMode } from "components/utils/deviceMode";
import React, { useEffect, useState } from "react";
import { ActivityIndicator, View, useWindowDimensions } from "react-native";
import { BackgroundLayout, Card, Scrollable } from "../../components";
import useUser from "../../hooks/useUser";
import { ParamsParamsList } from "../../navigation";
import useTheme from "../../theme/ThemeProvider";
import fnStyles from "./ParamsScreenStyle";
import AccountNotificationsBlock from "./sections/AccountNotificationsBlock";
import AccountInfosBlock from "./sections/AccountInfosBlock";
import AccountDiscretBlock from "./sections/AccountDiscretBlock";
import AccountShouldLoginBlock from "./sections/AccountShouldLoginBlock";
import AccountUnknownBlock from "./sections/AccountUnknownBlock";
import AccountLocationBlock from "./sections/AccountLocationBlock";
import useConfiguration from "../../configuration/ConfigurationProvider";
import JKeyboardAvoidingView from "components/common/JKeyboardAvoidingView/JKeyboardAvoidingView";
import AccountAreaOfInterestBlock from "./sections/AccountAreaOfInterestBlock";
import { GestureHandlerRootView } from "react-native-gesture-handler";
// import UpdatesBlock from "./UpdateBlock";

type Props = StackScreenProps<ParamsParamsList, "paramsHome">;

export const ParamsScreen: React.FC<Props> = ({ navigation }) => {
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const [isScrollEnabled, setIsScrollEnabled] = useState(true);
  const [verticalScrollOffset, setVerticalScrollOffset] = useState(0);

  const styles = fnStyles(theme, isTopBarMode({ width, height }));

  const {
    user,
    isRefreshFetching,
    isLoginMandatory,
    isUserAllowed,
    refreshAnonymousUser,
  } = useUser();
  const { configuration } = useConfiguration();

  const isUnknownedLoggedUser = isLoginMandatory && user && !isUserAllowed();

  // ajout d'un useEffect pour recharger l'utilisateur anonyme
  // car (au moins pour iOS) l'anonymous user ce s'est pas bien chargé (trop tôt)
  // surtout utile pour charger les notifications au premier chargement et affichage.
  useEffect(() => {
    refreshAnonymousUser();
  }, [refreshAnonymousUser]);

  const handleScroll = (event: any) => {
    setVerticalScrollOffset(event.nativeEvent.contentOffset.y);
  };

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <BackgroundLayout
        style={{
          marginTop: theme.normalize(-30),
          paddingTop: theme.normalize(30),
        }}
      >
        <JKeyboardAvoidingView offset={-200}>
          <Scrollable
            keyboardShouldPersistTaps={"handled"}
            additionalProps={{
              contentContainerStyle: styles.contentContainer,
              scrollEnabled: isTopBarMode({ width, height }) || isScrollEnabled,
              onScroll: handleScroll,
            }}
          >
            <BoxedLayout>
              {isRefreshFetching ? (
                <ActivityIndicator />
              ) : (
                <View
                  style={{
                    gap: theme.sizings.mediumLarge,
                    marginTop: theme.sizings.mediumLarge,
                  }}
                >
                  {isUnknownedLoggedUser && (
                    <Card style={styles.card}>
                      <AccountUnknownBlock />
                    </Card>
                  )}

                  {!user && (
                    <Card style={styles.card}>
                      <AccountShouldLoginBlock navigation={navigation} />
                    </Card>
                  )}

                  {user && (
                    <Card style={styles.card}>
                      <AccountInfosBlock navigation={navigation} />
                    </Card>
                  )}

                  {/* <UpdatesBlock />*/}

                  {configuration.sites.features.locationMandatory && (
                    <Card style={styles.dropDownMenuCard}>
                      <AccountLocationBlock />
                    </Card>
                  )}

                  {!isUnknownedLoggedUser && (
                    <Card style={styles.card}>
                      <AccountNotificationsBlock navigation={navigation} />
                    </Card>
                  )}

                  {user && (
                    <Card style={styles.card}>
                      <AccountAreaOfInterestBlock
                        setIsScrollEnabled={setIsScrollEnabled}
                        verticalScrollOffset={verticalScrollOffset}
                      />
                    </Card>
                  )}

                  {user && (
                    <Card style={styles.card}>
                      <AccountDiscretBlock navigation={navigation} />
                    </Card>
                  )}
                </View>
              )}
            </BoxedLayout>
          </Scrollable>
        </JKeyboardAvoidingView>
      </BackgroundLayout>
    </GestureHandlerRootView>
  );
};
