import { BoxedLayout } from "components/common/layout/BoxedLayout";
import { isTopBarMode } from "components/utils/deviceMode";
import React, { useState } from "react";
import {
  ImageBackground,
  ImageSourcePropType,
  ScrollView,
  View,
  useWindowDimensions,
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { I18nKey } from "../../../i18n";
import useUser from "../../hooks/useUser";
import useTheme from "../../theme/ThemeProvider";
import { Types } from "../../types";
import { Button, Icon, JText } from "../jlib";
import fnStyles from "./IntroductionLayoutStyle";
import { useTranslation } from "react-i18next";
import { LangType } from "theme/ThemeTypes";

type Step = {
  imageName: ImageSourcePropType;
  buttonLabelKey: I18nKey;
  buttonValuesKey?: object;
  description: string;
  iconName: string;
  iconType: Types["iconTypes"];
  titleKey?: I18nKey;
};

export const IntroductionScreen = () => {
  const { setDisplayIntroduction } = useUser();
  const { i18n } = useTranslation();

  const [step, setStep] = useState<number>(0);

  const handleContinue = () => {
    const newStep = step + 1;
    if (newStep > steps?.length - 1) {
      setDisplayIntroduction(false);
    } else {
      setStep((prevState) => prevState + 1);
    }
  };

  const { theme } = useTheme();

  const lang = i18n.language as LangType;

  const steps: Step[] = [
    {
      imageName: theme.images.introStep1,
      buttonLabelKey: "button.continue",
      description: theme.texts[lang]?.introStep1Title || "",
      iconName: theme.texts.icons.introStep1Icon,
      iconType: theme.texts.icons.introStep1IconType,
      titleKey: "titles.welcome",
    },
    {
      imageName: theme.images.introStep2,
      buttonLabelKey: "button.continue",
      description: theme.texts[lang]?.introStep2Title || "",
      iconName: theme.texts.icons.introStep2Icon,
      iconType: theme.texts.icons.introStep2IconType,
    },
    {
      imageName: theme.images.introStep3,
      buttonLabelKey: "button.seeYard",
      description: theme.texts[lang]?.introStep3Title || "",
      iconName: theme.texts.icons.introStep3Icon,
      iconType: theme.texts.icons.introStep3IconType,
    },
  ];
  return (
    <IntroductionLayout
      buttonLabelKey={steps[step].buttonLabelKey}
      buttonValuesKey={steps[step].buttonValuesKey}
      description={steps[step].description}
      iconName={steps[step].iconName}
      iconType={steps[step].iconType}
      imageName={steps[step].imageName}
      onPress={handleContinue}
      titleKey={steps[step].titleKey}
      step={step}
    />
  );
};

type Props = {
  title?: string;
  titleKey?: I18nKey;
  onPress: () => void;
  iconType: Types["iconTypes"];
  iconName: string;
  description: string;
  imageName: ImageSourcePropType;
  buttonLabel?: string;
  buttonLabelKey?: I18nKey;
  buttonValuesKey?: object;
  step: number;
};

export const IntroductionLayout: React.FC<Props> = ({
  title,
  titleKey,
  onPress,
  iconName,
  iconType,
  description,
  imageName,
  buttonLabel = "Continuer",
  buttonLabelKey = "button.continue",
  buttonValuesKey,
}) => {
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const isTopBar = isTopBarMode({ width, height });

  const styles = fnStyles(theme, width, height, isTopBar);

  const MainContent = () => (
    <View style={styles.mainContent}>
      <View style={styles.headerImageContainer}>
        <ImageBackground
          resizeMode="contain"
          source={theme.images.logo}
          style={styles.headerImage}
          alt={theme.texts.appName}
          accessibilityRole="header"
          accessible
        />
      </View>
      <View style={styles.iconContainer}>
        <Icon
          name={iconName}
          type={iconType}
          colorName="emphasisText"
          customSize={theme.normalize(40)}
          style={styles.icon}
        />
      </View>

      {(title || titleKey) && (
        <JText
          label={title}
          labelKey={titleKey}
          centered
          isBold
          titleLevel={2}
          style={{ marginBottom: theme.sizings.mediumLarge }}
        />
      )}
      <JText label={description} centered style={styles.description} />

      <Button
        onPress={onPress}
        type={"gradient"}
        label={buttonLabel}
        labelKey={buttonLabelKey}
        valuesKey={buttonValuesKey}
        containerStyle={styles.button}
      />
    </View>
  );

  return (
    <SafeAreaView edges={["top"]} style={styles.safe} role="main">
      <ScrollView>
        {isTopBar ? (
          <View style={styles.containerWeb}>
            <View style={styles.left}>
              <ImageBackground source={imageName} style={styles.leftImage} />
            </View>
            <ScrollView
              contentContainerStyle={styles.right}
              style={{ flex: 1 }}
            >
              <MainContent />
            </ScrollView>
          </View>
        ) : (
          <BoxedLayout style={{ alignItems: "center" }}>
            <View style={styles.topImageContainer}>
              <ImageBackground source={imageName} style={styles.topImage} />
            </View>

            <MainContent />
          </BoxedLayout>
        )}
      </ScrollView>
    </SafeAreaView>
  );
};
