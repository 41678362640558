import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Role,
  StyleProp,
  Text,
  TextStyle,
  useWindowDimensions,
} from "react-native";
import { I18nKey } from "../../../../i18n";
import useTheme from "../../../theme/ThemeProvider";
import { ColorType, FontSizeType } from "theme/ThemeTypes";
import { Types } from "../../../types";
import { isTopBarMode } from "components/utils/deviceMode";

export const defaultSizeMultiply = (
  forceSize: any,
  titleLevel: any,
  titleLevelStyle: any,
  width: number,
  height: number
) => {
  if (forceSize || !isTopBarMode({ width, height })) {
    return 1;
  }

  if ((titleLevel === 1 && !titleLevelStyle) || titleLevelStyle === 1) {
    return 1.3;
  }

  if (titleLevel === 2 || titleLevelStyle === 2) {
    return 1.2;
  }
  if (titleLevel === 3 || titleLevelStyle === 3) {
    return 1.15;
  }

  return 1;
};

export const font = (
  theme: any,
  textProps: {
    isTitleText?: boolean;
    sizeName?: any;
    fontWeight?: any;
    isButtonText?: boolean;
    colorName?: any;
  },
  multiplyProps: {
    forceSize?: any;
    titleLevel?: any;
    titleLevelStyle?: any;
    width: any;
    height: any;
  }
) => {
  const defaultColor = theme.colors.dark;
  const defaultSize = theme.normalize(theme.fontSizes.large);

  if (textProps.isTitleText) {
    return {
      fontFamily: theme.fonts.fontTitle,
      fontSize: textProps.sizeName
        ? theme.normalize(theme.fontSizes[textProps.sizeName]) *
          defaultSizeMultiply(
            multiplyProps.forceSize,
            multiplyProps.titleLevel,
            multiplyProps.titleLevelStyle,
            multiplyProps.width,
            multiplyProps.height
          )
        : defaultSize *
          defaultSizeMultiply(
            multiplyProps.forceSize,
            multiplyProps.titleLevel,
            multiplyProps.titleLevelStyle,
            multiplyProps.width,
            multiplyProps.height
          ),
      fontWeight: textProps.fontWeight || "600",
      marginBottom: theme.normalize(theme.sizings.large),
      color: textProps.colorName
        ? theme.colors[textProps.colorName]
        : theme.colors.dark,
    };
  }

  if (textProps.isButtonText) {
    return {
      fontFamily: theme.fonts.fontTitle,
      fontSize: textProps.sizeName
        ? theme.normalize(theme.fontSizes[textProps.sizeName])
        : theme.normalizeFont(18),
      fontWeight: textProps.fontWeight || "600",
      color: textProps.colorName
        ? theme.colors[textProps.colorName]
        : theme.colors.primary,
    };
  }

  return {
    fontFamily: theme.fonts.fontBody,
    fontSize: textProps.sizeName
      ? theme.normalize(theme.fontSizes[textProps.sizeName]) *
        defaultSizeMultiply(
          multiplyProps.forceSize,
          multiplyProps.titleLevel,
          multiplyProps.titleLevelStyle,
          multiplyProps.width,
          multiplyProps.height
        )
      : defaultSize *
        defaultSizeMultiply(
          multiplyProps.forceSize,
          multiplyProps.titleLevel,
          multiplyProps.titleLevelStyle,
          multiplyProps.width,
          multiplyProps.height
        ),
    color: textProps.colorName
      ? theme.colors[textProps.colorName]
      : defaultColor,
  };
};

type Props = {
  label?: string;
  labelKey?: I18nKey;
  valuesKey?: object;
  colorName?: ColorType;
  fontWeight?: Types["fontWeights"];
  sizeName?: FontSizeType;
  isUppercase?: boolean;
  isBold?: boolean;
  isItalic?: boolean;
  centered?: boolean;
  right?: boolean;
  isTitleText?: boolean;
  isButtonText?: boolean;
  numberOfLines?: number;
  role?: Role;
  nativeID?: string;
  titleLevel?: number;
  titleLevelStyle?: number;
  forceSize?: boolean;
  style?: StyleProp<TextStyle>;
  children?: React.ReactNode[] | React.ReactNode;
};

export const JText = ({
  label,
  labelKey,
  valuesKey = {},
  colorName,
  fontWeight,
  sizeName,
  isUppercase = false,
  isBold = false,
  isItalic = false,
  centered = false,
  right = false,
  isTitleText = false,
  isButtonText = false,
  numberOfLines = 0,
  nativeID,
  titleLevel,
  titleLevelStyle,
  forceSize,
  style,
  children,
  role,
}: Props) => {
  const { t } = useTranslation();
  const { width, height } = useWindowDimensions();

  const { theme } = useTheme();

  const templateFont = font(
    theme,
    {
      isTitleText,
      sizeName,
      fontWeight,
      isButtonText,
      colorName,
    },
    {
      forceSize,
      titleLevel,
      titleLevelStyle,
      width,
      height,
    }
  );

  const jTextStyle: StyleProp<TextStyle> = useMemo(
    () => [
      isBold
        ? { fontWeight: "700" }
        : isButtonText
        ? { fontWeight: "600" }
        : { fontWeight },

      isUppercase && { textTransform: "uppercase" },
      templateFont,

      {
        textAlign: centered ? "center" : right ? "right" : "left",
        fontStyle: isItalic ? "italic" : "normal",
      },
      style,
    ],
    [
      centered,
      fontWeight,
      isBold,
      isButtonText,
      isItalic,
      isUppercase,
      right,
      style,
      templateFont,
    ]
  );

  const labelResult = (
    children ? children : labelKey ? t(labelKey, valuesKey) : label
  ) as I18nKey;
  return (
    <Text
      style={jTextStyle}
      numberOfLines={numberOfLines}
      nativeID={nativeID}
      // @ts-ignore
      role={role ? role : titleLevel ? "heading" : "paragraph"}
      aria-level={titleLevel}
    >
      {labelResult}
    </Text>
  );
};
