import React, { createContext, useContext, useMemo } from "react";
import isDesktopMode, {
  isMobileMode,
  isTabletMode,
} from "components/utils/deviceMode";
import { PixelRatio, useWindowDimensions } from "react-native";
import defaultThemeProperties from "./default.theme";
import specificThemeProperties from "./specific.theme";
import { Theme, ThemeConfig } from "./ThemeTypes";
import { useSafeAreaInsets } from "react-native-safe-area-context";

export const baseSizeType = {
  mobile: {
    // Use iPhone6 as base size which is 375 x 667
    width: 375,
    height: 667,
  },
  tablet: {
    // Use iPad Mini Vertical
    width: 768,
    height: 1024,
  },
  /* Use iPad Mini Horizontal
  tablet: {
    width: 1024,
    height: 768,
  },*/
  desktop: {
    // not really used (except for createContext params) but here to be homogenous
    width: 1480,
    height: 1024,
  },
};

const getTheme = (
  width: number,
  height: number,
  scale = 1,
  isNormalized = false,
  baseSize = baseSizeType.desktop,
  insets = { top: 0, bottom: 0 }
): Theme => {
  const baseWidth = baseSize.width;
  const baseHeight = baseSize.height;
  const widthBaseScale = !isNormalized ? 1 : width / baseWidth;
  const heightBaseScale = !isNormalized ? 1 : height / baseHeight;

  const normalize = (pixel: number, based = "width") => {
    // kepp for memo : PixelRatio.get()
    const newSize =
      based === "height" ? pixel * heightBaseScale : pixel * widthBaseScale;

    return Math.round(PixelRatio.roundToNearestPixel(newSize));
  };
  const normalizeFont = (size: number) =>
    Math.round(normalize(size) / PixelRatio.getFontScale());

  // override each property (only for 1 or 2 levels are managed)
  const themeProperties: any = defaultThemeProperties;
  if (specificThemeProperties as Partial<ThemeConfig>) {
    // file is optional
    for (const [propKey, propValue] of Object.entries(
      specificThemeProperties as Partial<ThemeConfig>
    )) {
      if (
        Object.keys(propValue).length ||
        themeProperties.hasOwnProperty(propKey)
      ) {
        for (const [subPropKey, subPropValue] of Object.entries(propValue)) {
          themeProperties[propKey][subPropKey] = subPropValue;
        }
      } else {
        themeProperties[propKey] = propValue;
      }
    }
  }

  themeProperties.layout.navBottomHeight += insets.bottom;

  return {
    fonts: themeProperties.fonts,
    colors: themeProperties.colors,
    images: themeProperties.images,
    sizings: themeProperties.sizings,
    layout: themeProperties.layout,
    radius: themeProperties.radius,
    fontSizes: themeProperties.fontSizes,
    texts: themeProperties.texts,
    insets,
    normalize,
    normalizeFont,
    screen: {
      width,
      height,
      scale,
    },
  };
};

export const ThemeContext = createContext({
  theme: getTheme(baseSizeType.desktop.width, baseSizeType.desktop.height),
});

type Props = {
  children: React.ReactNode;
};

export const ThemeProvider: React.FC<Props> = ({ children }: Props) => {
  const { width, height, scale } = useWindowDimensions();
  const insets = useSafeAreaInsets();

  /*
  const context = useMemo(() => {
    return {
      theme: getTheme(width, height, scale),
    };
  }, [width, height, scale]);
  */

  const context = useMemo(() => {
    const isNormalized = !isDesktopMode({ width, height });

    let usedBaseSize = baseSizeType.desktop;

    if (isMobileMode({ width, height })) {
      usedBaseSize = baseSizeType.mobile;
    } else if (isTabletMode({ width, height })) {
      usedBaseSize = baseSizeType.tablet;
    }
    return {
      theme: getTheme(width, height, scale, isNormalized, usedBaseSize, insets),
    };
  }, [height, scale, width, insets]);

  return (
    <ThemeContext.Provider value={context}>{children}</ThemeContext.Provider>
  );
};

const useTheme = () => useContext(ThemeContext);

export default useTheme;
