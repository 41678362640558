import { Platform, StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme, isTopBarMode: boolean) =>
  StyleSheet.create({
    noRadiusContainer: {
      backgroundColor: theme.colors.white,
      shadowColor: theme.colors.dark,
      shadowOffset: {
        width: isTopBarMode ? 5 : 0,
        height: isTopBarMode ? 0 : 5,
      },
      shadowOpacity: 0.15,
      shadowRadius: 6.27,
      elevation: 10,
    },
    container: {
      backgroundColor: theme.colors.headerBackground,
      borderBottomColor: theme.colors.headerBackground,
      borderBottomLeftRadius: theme.normalize(theme.radius.mediumLarge),
      borderBottomRightRadius: theme.normalize(theme.radius.mediumLarge),
      shadowColor: theme.colors.dark,
      shadowOffset: {
        width: 0,
        height: 5,
      },
      shadowOpacity: 0.15,
      shadowRadius: 6.27,
      elevation: 10,
    },
    mainContainerWithoutShadow: {
      backgroundColor: theme.colors.headerBackground,
      borderBottomColor: theme.colors.headerBackground,
      borderBottomLeftRadius:
        Platform.OS !== "ios" ? theme.normalize(theme.radius.mediumLarge) : 0,
      borderBottomRightRadius:
        Platform.OS !== "ios" ? theme.normalize(theme.radius.mediumLarge) : 0,
      elevation: 0,
      shadowOpacity: 0,
    },
    containerStraight: {
      backgroundColor: theme.colors.headerBackground,
      borderBottomColor: theme.colors.headerBackground,
      shadowColor: theme.colors.dark,
      shadowOffset: {
        width: 0,
        height: 5,
      },
      shadowOpacity: 0.15,
      shadowRadius: 6.27,
      elevation: 10,
    },
    secondaryContainer: {
      backgroundColor: theme.colors.white,
      borderBottomLeftRadius: theme.normalize(theme.radius.mediumLarge),
      borderBottomRightRadius: theme.normalize(theme.radius.mediumLarge),
      shadowColor: theme.colors.dark,
      shadowOffset: {
        width: isTopBarMode ? 5 : 0,
        height: isTopBarMode ? 0 : 5,
      },
      shadowOpacity: 0.15,
      shadowRadius: 6.27,
      elevation: 10,
    },
    title: {
      fontWeight: "600",
      fontSize: theme.normalizeFont(theme.fontSizes.largeLow),
      color: theme.colors.headerText,
    },
    titleWithoutLeft: {
      marginLeft: isTopBarMode ? 85 : theme.normalize(theme.sizings.small),
      fontSize: theme.normalizeFont(theme.fontSizes.largeLow),
      color: theme.colors.headerText,
    },
    titleComponentWithoutLeft: {
      marginLeft: isTopBarMode ? 85 : theme.normalize(theme.sizings.small),
    },
    icon: {
      marginRight: theme.normalize(theme.sizings.medium),
    },
    iconImage: {
      width: theme.layout.minimalAccessible,
      height: theme.layout.minimalAccessible,
      borderRadius: theme.normalize(20),
    },
  });
