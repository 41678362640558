import { StackScreenProps } from "@react-navigation/stack";
import React from "react";
import { useWindowDimensions, View } from "react-native";
import { ChatParamsList } from "../../navigation";
import useTheme from "../../theme/ThemeProvider";
import fnStyles from "./ChatSelectPlaceStyles";
import { isTopBarMode } from "components/utils/deviceMode";
import Map from "../../components/jlib/map/Map";
import { zoomLevel } from "components/jlib/map/MapStyle";
import { Button, SView } from "components";
import useSites from "hooks/useSites";
import { useLocation, usePosts } from "hooks";

type Props = StackScreenProps<ChatParamsList, "chatSelectPlace">;

export const ChatSelectPlace: React.FC<Props> = ({ navigation }) => {
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();

  const styles = fnStyles(
    theme,
    isTopBarMode({ width, height }),
    width,
    height
  );

  const { filters, setSite } = useSites();
  const { setPost } = usePosts();

  const { locationData } = useLocation();

  const onSubmitPlace = () => {
    setSite(undefined);
    setPost(null);

    navigation.navigate("chatSelectType", {
      siteId: undefined,
      postId: undefined,
      lat: filters?.location ? filters?.location.lat : locationData.lat,
      lng: filters?.location ? filters?.location.lng : locationData.lng,
      address: filters?.location ? filters.location.name : locationData.label,
    });
  };

  return (
    <SView>
      <View style={styles.container}>
        <View style={styles.map}>
          <Map
            height={
              isTopBarMode({ width, height }) ? height * 0.5 : width * 0.8
            }
            key={"chat-select-place-map"}
            zoomLvl={zoomLevel.XHUGE}
            scrollEnabled={false}
            centeredOnUser
            google={undefined}
          />
        </View>
        <Button
          type={"gradient"}
          labelKey="chat.validatePlace"
          onPress={onSubmitPlace}
          containerStyle={styles.button}
          disabled={!filters?.location?.lat}
          // disabled={!canChangeInfos}
        />
      </View>
    </SView>
  );
};
