import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { StackScreenProps } from "@react-navigation/stack";
import { NewChatButton } from "components/chat/NewChatButton";
import { isTopBarMode } from "components/utils/deviceMode";
import useSites from "hooks/useSites";
import React, { useCallback, useEffect } from "react";
import { View, useWindowDimensions } from "react-native";
import {
  BackgroundLayout,
  Scrollable,
  TopNavigationBar,
} from "../../components";
import { SitesParamsList } from "../../navigation";
import useTheme from "../../theme/ThemeProvider";
import fnStyles from "./SiteDetailsScreenStyle";
import { SiteInformationScreen } from "./SiteInformationScreen";
import { SitePostsScreen } from "./SitePostsScreen";
import { SiteRoadmapScreen } from "./SiteRoadmapScreen";
import { SectionIntro } from "./sections/sectionIntro";
import useThread from "../../hooks/useThread";
import { useTranslation } from "react-i18next";
import { usePosts } from "hooks";
import { SiteQuestionScreen } from "./SiteQuestionsScreen";
import { SectionListZones } from "./sections/sectionListZones";

type Props = StackScreenProps<SitesParamsList, "siteDetails">;

const Tab = createMaterialTopTabNavigator<SitesParamsList>();

export const SiteDetailsScreen: React.FC<Props> = ({ route, navigation }) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { width, height } = useWindowDimensions();
  const { site, fetchSite, getSites } = useSites();
  const { setPost } = usePosts();

  const styles = fnStyles(
    theme,
    isTopBarMode({ width, height }),
    width,
    height
  );

  const onChatNav = () => {
    setPost(null);

    navigation.navigate("chatSelectType", {
      siteId: route.params.siteId,
      postId: undefined,
    });
  };

  useEffect(() => {
    // if (!site || site.id !== route.params.siteId) {
    fetchSite(route.params.siteId);
    getSites(); // on rajoute ca ici car quand on fait F5 les sites ne sont pas chargés (et les news sont récupérées via getSites)
    // }
  }, [fetchSite, getSites, route.params.siteId]);

  const { getThreadTypes } = useThread();
  const threadTypes = getThreadTypes(site);

  const tabBar = useCallback(
    (props: any) => <TopNavigationBar {...props} low />,
    []
  );

  if (!site) {
    return <View />;
  }

  return (
    <BackgroundLayout style={styles.container}>
      {Boolean(threadTypes?.length) && (
        <NewChatButton
          handleNav={onChatNav}
          navigation={navigation}
          bottomBar={false}
          isReaction
          spanHorizontal
        />
      )}

      {isTopBarMode({ width, height }) && (
        <Scrollable safePaddingBottom={false} style={styles.leftWeb}>
          <SectionIntro site={site} navigation={navigation} />
        </Scrollable>
      )}

      <Tab.Navigator
        initialRouteName={"siteInformation"}
        screenOptions={() => ({
          tabBarScrollEnabled: false,
          swipeEnabled: false,
          animationEnabled: false,
          title: site?.name
            ? `${site.name} - ${theme.texts.appName}`
            : `${t("header.siteDetails")} - ${theme.texts.appName}`,
          headerTitle: site?.name
            ? `${site?.name}`
            : t("header.siteDetails") ?? "",
        })}
        sceneContainerStyle={{ backgroundColor: "transparent" }}
        tabBar={tabBar}
      >
        <Tab.Screen
          name={"siteInformation"}
          component={SiteInformationScreen}
          options={() => ({
            title: site?.name
              ? `${t("header.siteInformation")} - ${site.name} - ${
                  theme.texts.appName
                }`
              : `${t("header.siteDetails")} - ${theme.texts.appName}`,
          })}
        />
        <Tab.Screen
          name={"sitePosts"}
          component={SitePostsScreen}
          options={() => ({
            title: site?.name
              ? `${t("header.sitePosts")} - ${site.name} - ${
                  theme.texts.appName
                }`
              : `${t("header.sitePosts")} - ${theme.texts.appName}`,
          })}
        />

        {Boolean(site?.children?.length) && (
          <Tab.Screen name={"siteZones"} component={SectionListZones} />
        )}

        {Boolean(site?.steps?.length) && (
          <Tab.Screen
            name={"siteRoadmap"}
            component={SiteRoadmapScreen}
            options={() => ({
              title: site?.name
                ? `${t("header.siteRoadmap")} - ${site.name} - ${
                    theme.texts.appName
                  }`
                : `${t("header.siteRoadmap")} - ${theme.texts.appName}`,
            })}
          />
        )}

        {Boolean(site?.qAndAs?.length) && (
          <Tab.Screen
            name={"siteQuestions"}
            component={SiteQuestionScreen}
            options={() => ({
              title: site?.name
                ? `${t("header.siteQuestions")} - ${site.name} - ${
                    theme.texts.appName
                  }`
                : `${t("header.siteQuestions")} - ${theme.texts.appName}`,
            })}
          />
        )}
      </Tab.Navigator>
    </BackgroundLayout>
  );
};
