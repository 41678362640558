import React from "react";
import {
  BackgroundLayout,
  Card,
  Divider,
  JText,
  Spacer,
} from "../../components";
import useTheme from "../../theme/ThemeProvider";
import fnStyles from "./LocationScreenStyle";
import { ImageBackground, useWindowDimensions } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";

import LocationHelper from "components/jlib/locationHelper/LocationHelper";
import { isTopBarMode } from "components/utils/deviceMode";
import JKeyboardAvoidingView from "components/common/JKeyboardAvoidingView/JKeyboardAvoidingView";

export const LocationScreen: React.FC = () => {
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const styles = fnStyles(theme, isTopBarMode({ width, height }));

  return (
    <BackgroundLayout>
      <JKeyboardAvoidingView offset={-50}>
        <SafeAreaView>
          <Card style={styles.contentContainer}>
            <ImageBackground
              source={theme.images.logo}
              style={styles.logo}
              alt={theme.texts.appName}
            />
            <Spacer height="large" />
            <JText
              labelKey="labels.aroundMeSite"
              isBold
              centered
              titleLevel={1}
              titleLevelStyle={2}
              sizeName="largeLow"
            />
            <Divider colorName="primary" style={styles.divider} />

            <JText
              labelKey="labels.aroundMeLegend"
              sizeName="average"
              centered
            />
            <LocationHelper />
          </Card>
        </SafeAreaView>
      </JKeyboardAvoidingView>
    </BackgroundLayout>
  );
};
