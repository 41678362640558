import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme, isTopBar: boolean, logoWidth: number) =>
  StyleSheet.create({
    logo: {
      width: theme.normalize(logoWidth),
      height: isTopBar ? theme.normalize(54) : theme.normalize(48),
      maxHeight: '100%'
    },
  });
