import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (
  theme: Theme,
  width: number,
  height: number,
  isTopBar: boolean
) =>
  StyleSheet.create({
    safe: {
      flex: 1,
    },
    headerImageContainer: {
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    headerImage: {
      width: theme.normalize(200),
      height: theme.normalize(100),
      marginTop: isTopBar ? 0 : theme.sizings.mediumLarge,
      marginBottom: theme.sizings.medium,
    },
    mainContent: {
      paddingLeft: isTopBar ? 
       theme.sizings.mediumLarge * 3 :
        theme.sizings.mediumLarge,
       paddingRight: theme.sizings.mediumLarge,
       alignItems: 'center'
    },
    button: {
      marginVertical: theme.sizings.mediumLarge
    },
    buttonContainer: {
      position: "absolute",
      top: theme.normalize(-24),
    },
    stackFooter: {
      width: "100%",
      height: isTopBar ? theme.normalize(150) : theme.normalize(300),
    },
    topImageContainer: {
      height: height * 0.25,
      width: "100%",
      borderBottomLeftRadius: theme.radius.large,
      borderBottomRightRadius: theme.radius.large,
      overflow: "hidden",
    },
    topImage: {
      height: "100%",
      width: "100%",
    },
    iconContainer: {
      width: "100%",
      alignItems: "center",
    },
    icon: {
      marginBottom: theme.sizings.medium,
    },
    containerWeb: {
      flexDirection: "row",
      width: "100%",
    },
    left: {
      flex: 1,
    },
    right: {
      flex: 1,
      minHeight: height,
      marginBottom: isTopBar ? theme.sizings.mediumLarge : undefined,
      justifyContent: "center",
      alignItems: "center"
    },
    leftImage: {
      height: height - theme.sizings.large * 2,
      width: "100%",
      borderRadius: theme.radius.large / 2,
      overflow: "hidden",
      margin: theme.sizings.large,
    },
    description: {
      width: "100%",
    },
    descriptionMore: {
      marginTop: theme.sizings.mediumLarge,
      paddingHorizontal: theme.sizings.mediumLarge,
      width: "100%",
    }
  });
