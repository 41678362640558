import React from "react";
import { JText } from "components";
import { View, useWindowDimensions } from "react-native";
import fnStyles from "./HeaderStyle";
import useTheme from "theme/ThemeProvider";
import { isTopBarMode } from "components/utils/deviceMode";

const HeaderTitleWithSubtitle = ({
  primaryText,
  secondaryText,
  spanLeft = false,
}: {
  primaryText: string;
  secondaryText?: string;
  spanLeft?: boolean;
}) => {
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const styles = fnStyles(theme, isTopBarMode({ width, height }));

  return (
    <View style={spanLeft ? styles.titleComponentWithoutLeft : {}}>
      <JText
        label={primaryText}
        colorName={"white"}
        role="heading"
        titleLevel={1}
        sizeName="largeLow"
        fontWeight="600"
        numberOfLines={1}
      />

      {secondaryText && (
        <JText
          label={secondaryText}
          colorName={"white"}
          sizeName={"mediumLarge"}
          numberOfLines={1}
          style={{ marginBottom: theme.sizings.tiny * 2 }}
        />
      )}
    </View>
  );
};

export default HeaderTitleWithSubtitle;
