import { DeleteAccountModal } from "../../../components/modals/deleteAccountModal";
import { isTopBarMode } from "../../../components/utils/deviceMode";
import React, { useState } from "react";
import { View, useWindowDimensions } from "react-native";
import { Clickable, JText } from "../../../components";
import useUser from "../../../hooks/useUser";
import useTheme from "../../../theme/ThemeProvider";
import fnStyles from "../ParamsScreenStyle";

const AccountDiscretBlock = ({ navigation }: any) => {
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const styles = fnStyles(theme, isTopBarMode({ width, height }));
  const { signout, isLoginMandatory } = useUser();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const onLogout = () => {
    signout();
    if (isLoginMandatory) {
      navigation.navigate("logIn");
    }
  };

  const onDeleteAccountButtonPressed = () => {
    setDeleteModalOpen(true);
  };
  return (
    <View>
      <Clickable onPress={onLogout}>
        <JText
          labelKey="auth.disconnectMe"
          colorName={"emphasisText"}
          centered
        />
      </Clickable>
      <Clickable
        onPress={onDeleteAccountButtonPressed}
        style={styles.deleteButton}
        aria-haspopup="dialog"
      >
        <JText
          labelKey="auth.deleteMyAccount"
          colorName={"grey"}
          centered
          sizeName="mediumLarge"
        />
      </Clickable>

      <DeleteAccountModal
        modalVisible={deleteModalOpen}
        setModalVisible={setDeleteModalOpen}
      />
    </View>
  );
};

export default AccountDiscretBlock;
