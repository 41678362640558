import dayjs from "dayjs";
import React, { useCallback } from "react";
import {
  FlatList,
  RefreshControl,
  StyleProp,
  useWindowDimensions,
  View,
  ViewStyle,
} from "react-native";
import useTheme from "../../theme/ThemeProvider";
import { Site } from "../../types/Site";
import { Thread } from "../../types/ThreadType";
import { JText, Scrollable, Spacer } from "../jlib";
import { ThreadItem } from "./ThreadItem";
import { isTopBarMode } from "components/utils/deviceMode";
import fnStyles from "./ThreadListStyle";
import { BoxedLayout } from "components/common/layout/BoxedLayout";
import { NoThread } from "./NoThread";

type Props = {
  //data: Thread[] | Site[];
  data: any[];
  onPress: ((thread: Thread) => void) | ((site: Site) => void);
  refreshing?: boolean;
  hasRefresh?: boolean;
  onRefresh?: () => void;
  contentContainerStyle?: StyleProp<ViewStyle>;
  isSite?: boolean;
};

// TODO : A REFACTO POUR EN FAIRE 2 DISTINCTS
export const ThreadList: React.FC<Props> = ({
  data,
  onPress,
  refreshing,
  hasRefresh = true,
  onRefresh,
  contentContainerStyle,
  isSite = false,
}) => {
  const { width, height } = useWindowDimensions();
  const { theme } = useTheme();
  // On tri les threads par date de dernier message
  const styles = fnStyles(theme, isTopBarMode({ width, height }));
  const dataList = isSite
    ? data
    : data
        .sort((a: Thread, b: Thread) => {
          const aLastMessage = a.messages.find(
            (message) =>
              Number(message.id) ===
              Math.max(...a?.messages?.map((m) => Number(m.id)))
          );
          const bLastMessage = b.messages.find(
            (message) =>
              Number(message.id) ===
              Math.max(...b?.messages?.map((m) => Number(m.id)))
          );
          return (
            Number(dayjs(aLastMessage?.createdAt)) -
            Number(dayjs(bLastMessage?.createdAt))
          );
        })
        .reverse();

  const spacer = useCallback(
    () => <Spacer height={"medium"} backgroundColor={"blurBackground"} />,
    []
  );
  return (
    <View style={styles.container}>
      {hasRefresh ? (
        <Scrollable>
          <BoxedLayout>
            {Boolean(isSite) && (
              <>
                <Spacer
                  height={"mediumLarge"}
                  backgroundColor={"transparent"}
                />

                <JText
                  labelKey="chat.choseSite"
                  isBold
                  centered
                  titleLevel={2}
                  style={{
                    zIndex: 10,
                  }}
                />
                <Spacer
                  height={"mediumLarge"}
                  backgroundColor={"transparent"}
                />
              </>
            )}

            {Boolean(isSite) || dataList?.length > 0 ? (
              <FlatList
                data={dataList}
                renderItem={({ item }) => {
                  return (
                    <ThreadItem item={item} onPress={onPress} isSite={isSite} />
                  );
                }}
                keyExtractor={(item) => item.id}
                ItemSeparatorComponent={spacer}
                contentContainerStyle={[
                  {
                    //paddingTop: theme.normalize(40),
                    paddingBottom: theme.normalize(150),
                  },
                  contentContainerStyle,
                ]}
                refreshControl={
                  <RefreshControl
                    refreshing={refreshing!}
                    onRefresh={onRefresh}
                  />
                }
              />
            ) : (
              <NoThread />
            )}
          </BoxedLayout>
        </Scrollable>
      ) : (
        <Scrollable>
          <BoxedLayout>
            {dataList?.length > 0 ? (
              <FlatList
                data={dataList}
                renderItem={({ item }) => (
                  <ThreadItem item={item} onPress={onPress} isSite={isSite} />
                )}
                keyExtractor={(item) => item.id}
                ItemSeparatorComponent={spacer}
                contentContainerStyle={[
                  {
                    paddingTop: theme.normalize(20),
                    paddingBottom: theme.normalize(150),
                  },
                  contentContainerStyle,
                ]}
              />
            ) : (
              <NoThread />
            )}
          </BoxedLayout>
        </Scrollable>
      )}
    </View>
  );
};
