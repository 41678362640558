import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme, isTopBarMode: boolean) =>
  StyleSheet.create({
    containerWeb: {
      flexDirection: "row",
      width: "100%",
    },
    left: {
      flex: 1,
    },
    right: {
      flex: 1,
    },
    webBottomSheetBox: {
      flex: 1,
      flexDirection: "row",
      position: "absolute",
      bottom: theme.layout.navBottomHeight + theme.sizings.medium,
      left: 0,
      width: "100%",
      borderTopLeftRadius: theme.normalize(theme.radius.medium),
      borderTopRightRadius: theme.normalize(theme.radius.medium),
    },
    webBottomSheetClose: {
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      top: 0,
      left: "50%",
      width: theme.sizings.larger,
      height: theme.sizings.larger,
      backgroundColor: theme.colors.secondary,
      transform: [
        { translateX: -theme.sizings.larger / 2 },
        { translateY: -theme.sizings.larger / 2 },
      ],
      borderRadius: theme.radius.rounded,
    },
    mobileBottomSheetClose: {
      justifyContent: "flex-end",
      alignItems: "flex-end",
      paddingHorizontal: theme.normalize(theme.sizings.medium),
      marginBottom: theme.sizings.larger,
      opacity: 0.5,
    },
    mobileBottomSheetBox: {
      //flex: 1,
      flexDirection: "row",
      paddingHorizontal: theme.normalize(theme.sizings.medium),
    },
    siteSuggest: {
      padding: theme.sizings.medium,
    },
    cardSiteSuggest: {
      alignItems: "center",
      gap: theme.sizings.medium,
    },
    siteSuggestImage: {
      width: theme.normalize(120),
      height: theme.normalize(120),
    },
    singleSiteSuggest: {
      marginTop: isTopBarMode ? theme.sizings.medium : theme.normalize(110),
    },
    flatList: {
      paddingBottom: isTopBarMode ? theme.normalize(100) : theme.normalize(200),
    },
    mapFields: {
      position: "absolute",
      top: 10,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      paddingHorizontal: theme.normalize(20),
    },
    mapButton: {
      backgroundColor: theme.colors.white,
      height: theme.normalize(40),
      paddingHorizontal: theme.normalize(10),
      borderRadius: theme.radius.medium,
      justifyContent: "center",
      alignItems: "center",
    },
    mapFields: {
      position: "absolute",
      top: 10,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      paddingHorizontal: theme.normalize(20),
    },
    mapButton: {
      backgroundColor: theme.colors.white,
      height: theme.normalize(40),
      paddingHorizontal: theme.normalize(10),
      borderRadius: theme.radius.medium,
      justifyContent: "center",
      alignItems: "center",
    },
  });
