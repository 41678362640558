import React, { useCallback, useMemo, useState } from "react";
import { View, useWindowDimensions } from "react-native";
import { StackScreenProps } from "@react-navigation/stack";
import { SitesParamsList } from "../../navigation";
import Map from "../../components/jlib/map/Map";
import { Clickable, ForegroundButton, Icon, SiteCard } from "components";
import useTheme from "theme/ThemeProvider";
import fnStyles from "./SitesHomeScreenStyle";
import useSites from "hooks/useSites";
import { isTopBarMode } from "components/utils/deviceMode";
import { Points } from "types/Site";
import { useTranslation } from "react-i18next";
import getWording from "utils/wording";
import useConfiguration from "../../configuration/ConfigurationProvider";

type Props = StackScreenProps<SitesParamsList, "siteMap">;

export const MapScreen: React.FC<Props> = ({ navigation }: Props) => {
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const styles = fnStyles(theme, isTopBarMode({ width, height }));
  const { sites, mapPolygonFilter } = useSites();
  const { t } = useTranslation();
  const { configuration } = useConfiguration();

  const [openSheet, setOpenSheet] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const points = useMemo(
    () =>
      sites
        ?.filter((item) => item.siteType?.isGeo)
        .map((item) => {
          return {
            id: item.id,
            lat: item.geojson?.lat,
            lng: item.geojson?.lng,
            name: item.name,
            isCustomMarkerSelected: item?.isCustomMarkerSelected,
            customMarkerSelected: item?.customMarkerSelected,
          } as Points;
        })
        .filter((p: Points) => Boolean(p.lat)),
    [sites]
  );

  const handleMarkerPress = useCallback(
    (e: any) => {
      // not exactely same than native

      const site = sites?.find((item) => item.id.toString() === e.toString());

      setOpenSheet(true);
      setSelectedItem(site);
    },
    [sites]
  );

  const handleSheetClose = () => {
    setOpenSheet(false);
    setSelectedItem(null);
  };

  const handleSeeSelectedPolygonSItes = () => {
    navigation.navigate("siteList");
  };

  return (
    <View>
      <Map
        fullHeight
        points={points}
        onMarkerPress={handleMarkerPress}
        zoomLvl={10} // used if geolocation
        centeredOnUser
        selectedPointId={selectedItem?.id}
        mapSearchAndDrawEnabled
      />
      {openSheet && (
        <View style={styles.webBottomSheetBox}>
          <SiteCard
            item={selectedItem}
            isWebFooterStyle
            navigation={navigation}
            titleLevel={2}
          />
          <Clickable
            onPress={handleSheetClose}
            style={styles.webBottomSheetClose}
          >
            <Icon
              name={"close"}
              colorName={"white"}
              sizeName={"mediumLarge"}
              customSize={theme.normalize(16)}
            />
          </Clickable>
        </View>
      )}
      {mapPolygonFilter?.length ? (
        <ForegroundButton
          handleButtonPressed={handleSeeSelectedPolygonSItes}
          buttonLabel={t("site.search", {
            wording: getWording(configuration).plural,
          })}
          iconName="keyboard-arrow-right"
          iconType="MaterialIcons"
          bottomBar={true}
          spanHorizontal
        />
      ) : null}
    </View>
  );
};
