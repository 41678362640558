export const isDesktopMode = (windowDimension: any) =>
  windowDimension.width > 1023;

export const isMobileMode = (windowDimension: any) =>
  windowDimension.width < 768;

export const isTabletMode = (windowDimension: any) =>
  windowDimension.width > 767 && windowDimension.width < 1023;

export const isTopBarMode = (windowDimension: any) =>
  windowDimension.width > 960;

export default isDesktopMode;
