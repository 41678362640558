import { Button } from "components/jlib";
import { isTopBarMode } from "components/utils/deviceMode";
import React, { ReactNode } from "react";
import { View, useWindowDimensions } from "react-native";
import useTheme from "theme/ThemeProvider";
import fnStyles from "./ForegroundButtonStyles";
import { BoxedLayout } from "components/common/layout/BoxedLayout";
import { Types } from "types";

type Props = {
  handleButtonPressed: () => any;
  bottomBar?: boolean;
  buttonLabel: string;
  iconType?: Types["iconTypes"];
  iconName?: string;
  spanHorizontal?: boolean;
  children?: ReactNode;
};

export const ForegroundButton: React.FC<Props> = ({
  handleButtonPressed,
  bottomBar = true,
  buttonLabel,
  iconType = "IonIcons",
  iconName = "chatbox",
  spanHorizontal = false,
  children,
}) => {
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();

  const styles = fnStyles(
    theme,
    isTopBarMode({ width, height }),
    bottomBar,
    spanHorizontal
  );

  return (
    <View style={styles.container}>
      <BoxedLayout>
        <Button
          onPress={handleButtonPressed}
          type={"action"}
          label={buttonLabel}
          iconType={iconType}
          iconName={iconName}
          style={styles.button}
          textStyle={styles.buttonLabel}
        />
        {children}
      </BoxedLayout>
    </View>
  );
};
