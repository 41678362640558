import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme, isTopBarMode: boolean) =>
  StyleSheet.create({
    container: {
      maxWidth: isTopBarMode ? theme.layout.containerMaxWidth : undefined,
      marginHorizontal: isTopBarMode ? "auto" : undefined,
      width: "100%",
    },
  });
