import hexToRgba from "hex-to-rgba";
import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme, width: number, isTopBarMode: boolean) =>
  StyleSheet.create({
    image: {
      height: isTopBarMode ? theme.normalize(250) : width * 0.7,
    },
    header: {
      flexDirection: "row",
      flexWrap: "wrap",
      marginVertical: theme.normalize(theme.sizings.medium),
      width: "100%",
      gap: theme.sizings.small,
    },
    headerLeft: {
      flex: 2,
      gap: theme.sizings.small,
    },
    headerRight: {
      flex: 1,
      alignItems: "flex-end",
    },
    siteName: {
      marginBottom: theme.sizings.small,
    },
    body: {
      marginHorizontal: theme.normalize(theme.sizings.medium),
    },
    section: {
      marginVertical: theme.normalize(theme.sizings.medium),
    },
    richText: {
      marginTop: theme.sizings.medium,
      marginBottom: theme.sizings.large / 2,
    },
    map: {
      width: "100%",
      height: isTopBarMode ? width * 0.4 : width * 0.8,
      backgroundColor: theme.colors.greyLight,
      marginVertical: theme.normalize(theme.sizings.medium),
      borderRadius: theme.radius.medium,
      overflow: "hidden",
    },
    keyNumbers: {
      marginTop: theme.normalize(theme.sizings.medium),
      flexWrap: "wrap",
    },
    keyNumber: {
      flex: 1,
      backgroundColor: theme.colors.infoLight,
      borderRadius: theme.normalize(15),
      paddingVertical: theme.normalize(theme.sizings.small),
      paddingHorizontal: theme.normalize(theme.sizings.medium),
      marginVertical: theme.normalize(5),
      maxWidth: isTopBarMode ? 300 : undefined,
    },
    keyNumberItem: {
      minWidth: "45%",
      maxWidth: "45%",
      marginRight: theme.normalize(10),
    },
    links: {
      marginTop: theme.normalize(theme.sizings.medium),
      flexWrap: "wrap",
    },
    link: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
      backgroundColor: hexToRgba(theme.colors.successLight, 0.2),
      borderRadius: theme.normalize(15),
      paddingVertical: theme.normalize(theme.sizings.small),
      paddingHorizontal: theme.normalize(theme.sizings.medium),
      marginVertical: theme.normalize(5),
      minHeight: theme.normalize(50),
      maxWidth: isTopBarMode ? 300 : undefined,
    },
    linkItem: {
      minWidth: "45%",
      maxWidth: "45%",
      marginRight: theme.normalize(10),
    },
    description: {
      letterSpacing: 0.6,
      marginTop: theme.normalize(theme.sizings.small),
      marginBottom: theme.normalize(theme.sizings.large / 2),
    },
    badge: {
      flexDirection: "row",
      alignItems: "center",
      alignSelf: "flex-start",
      paddingHorizontal: theme.sizings.smallMedium,
      paddingVertical: theme.sizings.tiny,
      borderRadius: theme.radius.medium,
      backgroundColor: theme.colors.dark,
      marginBottom: theme.sizings.medium,
    },
    pin: {
      marginLeft: theme.normalize(-5),
      marginRight: theme.sizings.tiny,
      position: "relative",
      top: theme.sizings.tiny / 2,
      width: theme.normalize(20),
    },
    mapIcon: {
      marginRight: theme.sizings.small,
    },
    contactRow: {
      flexDirection: "row",
      marginTop: theme.normalize(6),
      flexWrap: "wrap",
    },
  });
