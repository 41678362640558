/* eslint-disable react/no-unstable-nested-components */
import React, { useCallback } from "react";
import {
  CardStyleInterpolators,
  createStackNavigator,
} from "@react-navigation/stack";
import {
  ChatScreen,
  ReactMessageScreen,
  ChatSelectType,
  ChatSelectSiteOrPlace,
  ChatContactProfileScreen,
  ChatSelectUserType,
} from "../screens";
import { ChatParamsList } from "./ChatParamsList";
import useTheme from "../theme/ThemeProvider";
import fnStyles from "./HeaderStyle";
import { Clickable, Icon, JImage } from "../components";
import { View, useWindowDimensions } from "react-native";
import { isTopBarMode } from "../components/utils/deviceMode";
import getWording from "../utils/wording";
import useThread from "../hooks/useThread";
import { useTranslation } from "react-i18next";
import HeaderTitleWithSubtitle from "./HeaderTitleWithSubtitle";
import useConfiguration from "../configuration/ConfigurationProvider";

const ChatStack = createStackNavigator<ChatParamsList>();

export const hideTabBarComponents = ["ProductDetails"];

export const ChatStackNavigator: React.FC = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { width, height } = useWindowDimensions();
  const { configuration } = useConfiguration();
  const styles = fnStyles(theme, isTopBarMode({ width, height }));

  const { thread, getThreadType } = useThread();

  const headerLeftStyle = {
    marginLeft: isTopBarMode({ width, height })
      ? 85
      : theme.normalize(theme.sizings.small),
  };

  const renderHeaderTitleWithSubtitle = useCallback(
    (primaryText?: string, secondaryText?: string) => {
      return (
        <HeaderTitleWithSubtitle
          primaryText={primaryText || ""}
          secondaryText={secondaryText}
        />
      );
    },
    []
  );

  return (
    <ChatStack.Navigator
      screenOptions={({ route }) => ({
        cardStyleInterpolator: CardStyleInterpolators.forNoAnimation,
        headerTitleAlign:
          route.name === "chatHome" || route.name === "contactProfile"
            ? "center"
            : "left",
      })}
    >
      <ChatStack.Screen
        name={"chatHome"}
        component={ChatScreen}
        options={{
          title: `${t("header.myThreads")} - ${theme.texts.appName}`,
          headerTitle: t("header.myThreads") ?? "",
          headerStyle: styles.container,
          headerTintColor: theme.colors.white,
          headerTitleStyle: styles.titleWithoutLeft,
        }}
      />
      <ChatStack.Screen
        name={"chatSelectSiteOrPlace"}
        component={ChatSelectSiteOrPlace}
        options={{
          title: `${t("header.selectSite")} - ${theme.texts.appName}`,
          headerTitleStyle: styles.title,
          headerTitle: () =>
            renderHeaderTitleWithSubtitle(
              t("header.newThread"),
              t("header.choseSite", {
                wording: getWording(configuration).singular,
              })
            ),
          headerStyle: styles.container,
          headerLeft: (props) => (
            <Clickable
              onPress={props.onPress!}
              style={headerLeftStyle}
              ariaLabel="Retour à la page précédente"
            >
              <Icon
                name={"chevron-small-left"}
                type={"Entypo"}
                colorName={"white"}
                sizeName={"high"}
                accessibilityLabel="Retour"
              />
            </Clickable>
          ),
        }}
      />
      <ChatStack.Screen
        name={"chatSelectType"}
        component={ChatSelectType}
        options={{
          title: `${t("header.choseReason")} - ${theme.texts.appName}`,
          headerTitle: () =>
            renderHeaderTitleWithSubtitle(
              t("header.newThread"),
              t("header.reasonType")
            ),
          headerStyle: styles.container,
          headerLeft: (props) => (
            <Clickable
              onPress={props.onPress!}
              style={headerLeftStyle}
              ariaLabel="Retour à la page précédente"
            >
              <Icon
                name={"chevron-small-left"}
                type={"Entypo"}
                colorName={"white"}
                sizeName={"high"}
                accessibilityLabel="Retour"
              />
            </Clickable>
          ),
        }}
      />
      <ChatStack.Screen
        name={"chatSelectUserType"}
        component={ChatSelectUserType}
        options={{
          title: `${t("header.choseUserType")} - ${theme.texts.appName}`,
          headerTitleStyle: styles.title,
          headerTitle: () =>
            renderHeaderTitleWithSubtitle(
              t("header.newThread"),
              t("header.choseUserType", {
                wording: getWording(configuration).singular,
              })
            ),
          headerStyle: styles.container,
          headerLeft: (props) => (
            <Clickable
              onPress={props.onPress!}
              style={headerLeftStyle}
              ariaLabel="Retour à la page précédente"
            >
              <Icon
                name={"chevron-small-left"}
                type={"Entypo"}
                colorName={"white"}
                sizeName={"high"}
                accessibilityLabel="Retour"
              />
            </Clickable>
          ),
        }}
      />
      <ChatStack.Screen
        name={"reactMessage"}
        component={ReactMessageScreen}
        options={({ route }) => {
          const threadType = thread
            ? thread.type
            : getThreadType(route.params.threadTypeId);

          return {
            title: `${t("header.yourMessage")} - ${theme.texts.appName}`,
            headerTitleStyle: styles.title,
            headerStyle: styles.container,
            headerTitle: thread
              ? () =>
                  renderHeaderTitleWithSubtitle(
                    thread.site?.name ??
                      t("chat.noSiteSelected", {
                        wording: getWording(configuration).singular,
                      }),
                    thread.type?.name
                  )
              : undefined,
            headerLeft: (props) => (
              <Clickable
                onPress={props.onPress!}
                style={headerLeftStyle}
                ariaLabel="Retour à la page précédente"
              >
                <Icon
                  name={"chevron-small-left"}
                  type={"Entypo"}
                  colorName={"white"}
                  sizeName={"high"}
                  accessibilityLabel="Retour"
                />
              </Clickable>
            ),
            headerRight: () => (
              <View
                style={{ marginRight: theme.normalize(theme.sizings.medium) }}
              >
                {threadType?.imageDarkMode ? (
                  <JImage
                    source={threadType?.imageDarkMode.url}
                    type={"Uri"}
                    style={styles.iconImage}
                    alt={t("titles.illustrationOf", { title: threadType.name })}
                  />
                ) : (
                  <></>
                )}
              </View>
            ),
          };
        }}
      />
      <ChatStack.Screen
        name={"contactProfile"}
        component={ChatContactProfileScreen}
        options={{
          title: `${t("header.contactInfo")} - ${theme.texts.appName}`,
          headerTitle: t("header.contactInfo") ?? "",
          headerStyle: styles.container,
          headerTitleStyle: styles.title,
          headerLeft: (props) => (
            <Clickable
              onPress={props.onPress!}
              style={headerLeftStyle}
              ariaLabel="Retour à la page précédente"
            >
              <Icon
                name={"chevron-small-left"}
                type={"Entypo"}
                colorName={"white"}
                sizeName={"high"}
                accessibilityLabel="Retour"
              />
            </Clickable>
          ),
        }}
      />
    </ChatStack.Navigator>
  );
};
