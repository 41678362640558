import React, { useEffect } from "react";
import {
  View,
  Modal,
  ViewStyle,
  useWindowDimensions,
  TouchableOpacity,
  ScrollView,
  Platform,
} from "react-native";
import { Button, Clickable, JText, Spacer } from "../jlib";
import useTheme from "../../theme/ThemeProvider";
//import fnStyles from "./DeleteAccountModalStyle";
import { useUser } from "../../hooks/useUser";
import { BoxedLayout } from "components/common/layout/BoxedLayout";
import { useTranslation } from "react-i18next";

type Props = {
  modalVisible: boolean;
  setModalVisible: any;
};

export const DeleteAccountModal: React.FC<Props> = ({
  modalVisible,
  setModalVisible,
}) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  //const styles = fnStyles(theme);
  const { height, width } = useWindowDimensions();
  const { user, deleteUser } = useUser();

  const handleDeleteConfirmation = async () => {
    if (user) {
      await deleteUser(user.id);
    }
    setModalVisible(false);
  };

  const layerStyle = {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#00000095",
    height,
  } as ViewStyle;

  const panelStyle = {
    padding: theme.sizings.mediumLarge,
    backgroundColor: theme.colors.white,
    borderRadius: theme.radius.mediumLarge,
    maxWidth: width - theme.sizings.mediumLarge * 2,
    maxHeight: height - theme.sizings.mediumLarge * 2 - theme.sizings.huge,
  } as ViewStyle;

  const footerStyle = {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  } as ViewStyle;

  useEffect(() => {
    if (Platform.OS === "web") {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
          setModalVisible(false);
        }
      };

      // Ajout de l'écouteur uniquement pour le Web
      window.addEventListener("keydown", handleKeyDown);

      return () => {
        // Nettoyage
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [setModalVisible]);

  return (
    <Modal
      visible={modalVisible}
      animationType="fade"
      hardwareAccelerated
      transparent
    >
      <TouchableOpacity
        onPress={() => {
          setModalVisible(false);
        }}
        style={layerStyle}
        accessibilityLabel={t("modal.deleteAccount")}
      >
        <View style={panelStyle}>
          <ScrollView>
            <BoxedLayout>
              <JText
                labelKey="modal.deleteAccount"
                centered
                sizeName="mediumLarge"
                titleLevel={2}
              />
              <Spacer height="mediumLarge" backgroundColor="transparent" />
              <View style={footerStyle}>
                <Button
                  type={"gradient"}
                  labelKey="common.confirm"
                  onPress={handleDeleteConfirmation}
                />
                <Spacer
                  height="mediumLarge"
                  width="mediumLarge"
                  backgroundColor="transparent"
                />
                <Clickable onPress={() => setModalVisible(false)}>
                  <JText
                    labelKey="common.cancel"
                    colorName={"emphasisText"}
                    isBold
                    centered
                  />
                </Clickable>
              </View>
            </BoxedLayout>
          </ScrollView>
        </View>
      </TouchableOpacity>
    </Modal>
  );
};
