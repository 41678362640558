import { Platform, StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (
  theme: Theme,
  isTopBarMode: boolean,
  navbarShow: boolean,
  spanHorizontal: boolean
) =>
  StyleSheet.create({
    container: {
      position: "absolute",
      width: isTopBarMode && spanHorizontal ? "65%" : "100%",
      bottom: 0,
      left: isTopBarMode && spanHorizontal ? "35%" : 0,
      zIndex: 10,
    },
    button: {
      position: "absolute",
      bottom: isTopBarMode
        ? theme.normalize(16)
        : navbarShow
        ? Platform.OS === "web"
          ? theme.layout.navBottomHeight + theme.normalize(48)
          : theme.layout.navBottomHeight + theme.normalize(12)
        : theme.insets.bottom + theme.normalize(16),
      right: theme.normalize(16),
    },
    buttonLabel: {
      marginLeft: theme.normalize(theme.sizings.large),
      marginRight: theme.normalize(theme.sizings.mediumLarge),
    },
  });
