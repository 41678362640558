import {
  CardStyleInterpolators,
  createStackNavigator,
} from "@react-navigation/stack";
import { isTopBarMode } from "components/utils/deviceMode";
import React, { useCallback, useMemo } from "react";
import { Platform, useWindowDimensions } from "react-native";
import { ReactHomeScreen } from "screens/request";
import { Clickable, HeaderLogo, Icon } from "../components";
import { usePosts } from "../hooks";
import {
  PostDetailsScreen,
  PostsHomeScreen,
  SiteDetailsScreen,
} from "../screens";
import useTheme from "../theme/ThemeProvider";
import fnStyles from "./HeaderStyle";
import { PostsParamsList } from "./PostsParamsList";
import { useTranslation } from "react-i18next";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import HeaderTitleWithSubtitle from "./HeaderTitleWithSubtitle";

const PostsStack = createStackNavigator<PostsParamsList>();

export const PostsStackNavigator: React.FC = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { width, height } = useWindowDimensions();

  const { post } = usePosts();

  const insets = useSafeAreaInsets();

  const isScreenHorizontal = useMemo(
    () => isTopBarMode({ width, height }),
    [height, width]
  );

  const styles = fnStyles(theme, isScreenHorizontal);

  const headerLeftStyle = useMemo(
    () => ({
      marginLeft: isScreenHorizontal
        ? 85
        : theme.normalize(theme.sizings.small),
    }),
    [isScreenHorizontal, theme]
  );

  const headerLogo = useCallback(
    (isShown: boolean) => isShown && <HeaderLogo />,
    []
  );

  const headerLeft = useCallback(
    (props: any) => (
      <Clickable
        onPress={props.onPress!}
        style={headerLeftStyle}
        ariaLabel="Retour à la page précédente"
      >
        <Icon
          name={"chevron-small-left"}
          type={"Entypo"}
          colorName={"white"}
          sizeName={"high"}
          style={{ marginLeft: theme.sizings.small }}
          accessibilityLabel="Retour"
        />
      </Clickable>
    ),
    [headerLeftStyle, theme]
  );

  const renderHeaderTitleWithSubtitle = useCallback(
    (primaryText?: string, secondaryText?: string) => {
      return (
        <HeaderTitleWithSubtitle
          primaryText={primaryText || ""}
          secondaryText={secondaryText}
        />
      );
    },
    []
  );

  return (
    <PostsStack.Navigator
      screenOptions={({ route }) => ({
        cardStyleInterpolator: CardStyleInterpolators.forNoAnimation,
        headerTitleAlign:
          route.name === "postsHome" ||
          route.name === "chatSelectType" ||
          route.name === "postsFollowedSites"
            ? "center"
            : "left",
        headerShown: route.name !== "chatSelectType",
      })}
    >
      <PostsStack.Screen
        name={"postsHome"}
        component={PostsHomeScreen}
        options={() => ({
          title: `${t("header.welcomeApp", { name: theme.texts.appName })} - ${
            theme.texts.appName
          }`,
          headerTitle: () => headerLogo(isScreenHorizontal),
          headerStyle:
            Platform.OS === "web"
              ? styles.noRadiusContainer
              : {
                  ...styles.secondaryContainer,
                  height: 60 + insets.top,
                },
          headerLeft: () => headerLogo(!isScreenHorizontal),
        })}
      />
      <PostsStack.Screen
        name={"postDetails"}
        component={PostDetailsScreen}
        options={() => ({
          title: `${t("header.postDetails")} - ${theme.texts.appName}`,
          headerTitle: () =>
            renderHeaderTitleWithSubtitle(post?.title, post?.site?.name),
          headerLeft: headerLeft,
          headerStyle: post?.mainImage
            ? styles.containerStraight
            : styles.container,
        })}
      />
      <PostsStack.Screen
        name={"chatSelectType"}
        component={ReactHomeScreen}
        options={{
          title: `${t("header.react")} - ${theme.texts.appName}`,
          headerTitle: t("header.react") ?? "",
        }}
      />

      <PostsStack.Screen
        name={"siteDetails"}
        component={SiteDetailsScreen}
        options={() => ({
          title: post?.site?.name
            ? `${post?.site?.name} - ${theme.texts.appName}`
            : `${t("header.siteDetails")} - ${theme.texts.appName}`,
          headerTitle: () =>
            renderHeaderTitleWithSubtitle(
              post?.site?.name,
              post?.site?.location
            ),
          headerLeft: headerLeft,
          headerStyle:
            post?.site?.logo || post?.site?.mainImage
              ? styles.containerStraight
              : styles.container,
        })}
      />
    </PostsStack.Navigator>
  );
};
