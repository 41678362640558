import { isTopBarMode } from "components/utils/deviceMode";
import React from "react";
import {
  Platform,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native";
import {
  DateDisplay,
  Divider,
  Icon,
  JImage,
  JText,
  Row,
} from "../../../components";
import useTheme from "../../../theme/ThemeProvider";
import fnStyles from "../SiteInformationScreenStyle";
import * as Linking from "expo-linking";
import FollowSwitch from "components/common/switch/FollowSwitch";
import useConfiguration from "../../../configuration/ConfigurationProvider";
import { SiteDistance } from "components/sites/SiteDistance";
import BadgeDemo from "components/common/badgeDemo.tsx/BadgeDemo";
import useSites from "hooks/useSites";
import { useLocation } from "hooks";

type Props = {
  site: any;
  navigation: any;
};

export const SectionIntro: React.FC<Props> = ({ navigation, site }) => {
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const { configuration } = useConfiguration();
  const { filters } = useSites();
  const { locationData } = useLocation();

  const styles = fnStyles(theme, width, isTopBarMode({ width, height }));

  return (
    <>
      {site?.mainImage?.url && (
        <JImage source={site.mainImage.url} type={"Uri"} style={styles.image} />
      )}
      <View
        style={[
          styles.body,
          {
            marginTop:
              !isTopBarMode({ width, height }) && !site.mainImage
                ? theme.normalize(site?.mainImage?.url ? 30 : 80)
                : 0,
          },
        ]}
      >
        <View style={styles.header}>
          <View style={styles.headerLeft}>
            {site?.isDraft && <BadgeDemo />}

            <JText
              label={site.name}
              style={styles.siteName}
              isBold
              role="heading"
              titleLevel={2}
              titleLevelStyle={1}
            />

            {Boolean(configuration?.sites.display.organization) && (
              <Row>
                <Icon
                  name={"supervised-user-circle"}
                  type="MaterialIcons"
                  colorName={"transparentDark"}
                  style={[styles.pin, { left: theme.sizings.tiny / 2 }]}
                  sizeName="mediumLarge"
                />
                <JText
                  label={site.organization?.name}
                  sizeName="mediumLarge"
                  numberOfLines={1}
                  colorName={"dark"}
                />
              </Row>
            )}

            {Boolean(site.location) ||
              (site?.siteType?.isGeo && site?.geojson?.lat && (
                <Row>
                  {site.location && (
                    <Row style={{ marginRight: theme.sizings.small }}>
                      <Icon
                        name={"location-pin"}
                        type="Entypo"
                        colorName={"info"}
                        style={styles.pin}
                      />
                      <JText
                        label={site.location}
                        sizeName="mediumLarge"
                        colorName={"dark"}
                      />
                    </Row>
                  )}
                  {site?.siteType?.isGeo &&
                  site?.geojson?.lat &&
                  (filters?.location?.lat || locationData?.lat) ? (
                    <SiteDistance siteLocation={site?.geojson} />
                  ) : null}
                </Row>
              ))}
          </View>

          <View style={styles.headerRight}>
            <FollowSwitch
              site={site}
              navigation={navigation}
              canFollowParent={true}
            />
          </View>

          {site.siteType.shouldHaveDates &&
            (site.startDate || site.endDate) && (
              <DateDisplay
                startDate={
                  site.startDate ? new Date(site.startDate!) : undefined
                }
                endDate={site.endDate ? new Date(site.endDate!) : undefined}
                type={"large"}
                hideHours
              />
            )}
        </View>

        {Boolean(site.links?.length) && (
          <>
            <Divider />
            <View style={styles.section}>
              <JText
                labelKey="site.links"
                isBold
                sizeName={"largeLow"}
                titleLevel={3}
              />
              <Row style={styles.links}>
                {site.links?.map((link: any, index: number) => {
                  return (
                    <TouchableOpacity
                      onPress={() => {
                        if (Platform.OS === "web") {
                          window.open(link.target, "_blank");
                        } else {
                          Linking.openURL(link.target);
                        }
                      }}
                      style={[
                        styles.link,
                        site.links.length > 1 ? styles.linkItem : null,
                      ]}
                      key={`link-${index}`}
                      accessibilityRole="link"
                      aria-label={link.label}
                    >
                      <JText
                        label={link.label}
                        sizeName={"largeLow"}
                        colorName="dark"
                      />
                      <Icon
                        name="angle-right"
                        type="FontAwesome"
                        colorName="success"
                      />
                    </TouchableOpacity>
                  );
                })}
              </Row>
            </View>
          </>
        )}

        {Boolean(site.keyIndicators?.length) && (
          <>
            <Divider />
            <View style={styles.section} role="list">
              <JText
                labelKey="site.keyNumbers"
                isBold
                sizeName={"largeLow"}
                titleLevel={3}
              />
              <Row style={styles.keyNumbers}>
                {site.keyIndicators?.map((kI: any, index: number) => {
                  return (
                    <View
                      style={[
                        styles.keyNumber,
                        site.keyIndicators.length > 1
                          ? styles.keyNumberItem
                          : null,
                      ]}
                      key={`keyNumber-${index}`}
                      role="listitem"
                    >
                      <JText label={kI.label} sizeName={"largeLow"} />
                      <JText
                        label={kI.value}
                        colorName={"infoMedium"}
                        isBold
                        sizeName={"largeLow"}
                      />
                    </View>
                  );
                })}
              </Row>
            </View>
          </>
        )}
      </View>
    </>
  );
};
