import React, { useState } from "react";
import { StackScreenProps } from "@react-navigation/stack";
import { PostsParamsList } from "../../navigation";
import {
  Clickable,
  Divider,
  DocumentItem,
  Icon,
  JImage,
  JText,
  Scrollable,
} from "../../components";
import { ActivityIndicator, View, useWindowDimensions } from "react-native";
import useTheme from "../../theme/ThemeProvider";
import fnStyles from "./PostInformationScreenStyle";
import { BoxedLayout } from "components/common/layout/BoxedLayout";
import { SectionIntro } from "./sections/sectionIntro";
import { isTopBarMode } from "components/utils/deviceMode";
import Map from "../../components/jlib/map/Map";
import { zoomLevel } from "../../components/jlib/map/MapStyle";
import useOrganization from "../../hooks/useOrganization";
import { usePosts, useUser } from "hooks";
import { useTranslation } from "react-i18next";
import getStatusBarHeight from "utils/getStatusBarHeight";
import { Post } from "types/Post";
import dayjs from "dayjs";
import { JHtml } from "components/jlib/jHtml/JHtml";

type Props = StackScreenProps<PostsParamsList, "postInformation">;

const getAlertLevel = (
  level: "low" | "medium" | "high" | undefined,
  t: any
) => {
  switch (level) {
    case "low":
      return t("post.levelLow");
    case "medium":
      return t("post.levelMedium");
    case "high":
      return t("post.levelHigh");
    default:
      return t("post.levelLow");
  }
};

export const PostInformationScreen: React.FC<Props> = ({
  navigation,
  route,
}) => {
  const { t } = useTranslation();
  const { post, onUpdateUpVoted } = usePosts();
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const { organization: currentOrganization } = useOrganization();
  const contents = post?.content?.split("\n");
  const contentsAdvice = post?.advice?.split("\n");
  const contentsAction = post?.action?.split("\n");

  const { localHistoryThanksPosts } = useUser();

  const [isLikeUpdating, setIsLikeUpdating] = useState(false);

  const onLike = async () => {
    setIsLikeUpdating(true);
    await onUpdateUpVoted(post as Post);
    setIsLikeUpdating(false);
  };

  const hasLiked = Boolean(
    localHistoryThanksPosts?.find(
      (postThanksId: any) => postThanksId === post?.id
    )
  );

  const styles = fnStyles(theme, width, isTopBarMode({ width, height }));

  if (!post) {
    return <View />;
  }

  const postPoint =
    (post.geojson?.lat || post.site?.geojson?.lat) &&
    post.site?.siteType?.isGeo &&
    !(currentOrganization && !currentOrganization.activeModuleMap)
      ? {
          id: post.id,
          lat: post.geojson?.lat || post.site?.geojson?.lat || 0,
          lng: post.geojson?.lng || post.site?.geojson?.lng || 0,
          name: post.site?.name || "",
          isCustomMarkerSelected: post.site?.isCustomMarkerSelected,
          customMarkerSelected: post.site?.customMarkerSelected,
        }
      : null;

  return (
    <Scrollable style={{ backgroundColor: theme.colors.white }}>
      <BoxedLayout
        style={{
          marginTop: isTopBarMode({ width, height })
            ? post?.mainImage
              ? theme.normalize(75)
              : theme.normalize(60)
            : getStatusBarHeight(),
        }}
      >
        <SectionIntro
          post={post}
          navigation={navigation}
          fromSite={route.params.fromSite}
        />

        <View style={[styles.content]}>
          {Boolean(
            post.type.shouldHavePerturbation &&
              (post.alertType || post.alertLevel)
          ) && (
            <>
              <Divider margin={"medium"} />

              <JText
                labelKey={"post.perturbation"}
                isBold
                sizeName={"largeLow"}
              />

              <View style={styles.alertContainer}>
                {Boolean(post.alertType) && (
                  <View style={styles.alertType}>
                    <JText labelKey={"post.type"} sizeName={"largeLow"} />

                    <JText
                      label={post.alertType?.name}
                      sizeName={"largeLow"}
                      style={styles.alertInfo}
                      colorName="primaryDarker"
                      isBold
                    />

                    {post.alertType?.icon ? (
                      <JImage
                        source={post.alertType.icon?.url || ""}
                        type={"Uri"}
                        style={styles.alertIcon}
                      />
                    ) : (
                      <Icon
                        name="bell"
                        type="FontAwesome"
                        colorName="primaryDarker"
                        sizeName="larger"
                      />
                    )}
                  </View>
                )}

                {Boolean(post.alertLevel) && (
                  <View style={styles.alertLevel}>
                    <JText labelKey="post.level" sizeName={"largeLow"} />
                    <JText
                      label={getAlertLevel(post.alertLevel, t)}
                      sizeName={"largeLow"}
                      style={styles.alertInfo}
                      colorName={"info"}
                      isBold
                    />
                  </View>
                )}
              </View>
            </>
          )}

          {post?.contentRich ? (
            <>
              <Divider margin={"medium"} />

              <JHtml
                source={post?.contentRich}
                style={styles.richText}
                //    contentWidth={width}
              />
            </>
          ) : (
            contents?.map((content: string, key: number) => (
              <View key={`content-${key}`}>
                <JText
                  label={content}
                  sizeName={"mediumLarge"}
                  style={styles.description}
                />
              </View>
            ))
          )}

          {post.documents && (
            <>
              <Divider margin={"medium"} />

              <JText
                labelKey="post.documents"
                isBold
                sizeName={"largeLow"}
                titleLevel={3}
              />
              {post.documents.map((document, index) => {
                return (
                  <DocumentItem
                    document={document}
                    key={index}
                    subject={post?.title}
                  />
                );
              })}
            </>
          )}
          {(postPoint || post.location) && (
            <>
              <Divider margin={"medium"} />

              {!!postPoint && (
                <View style={styles.map}>
                  <Map
                    height={
                      isTopBarMode({ width, height })
                        ? width * 0.4
                        : width * 0.8
                    }
                    points={[postPoint]}
                    zoomLvl={zoomLevel.XHUGE}
                    initialRegion={postPoint}
                    scrollEnabled={false}
                  />
                </View>
              )}

              {Boolean(post.location) && (
                <JText
                  label={post.location}
                  sizeName={"mediumLarge"}
                  isItalic
                  style={{
                    marginTop: postPoint ? theme.sizings.medium : 0,
                  }}
                />
              )}
            </>
          )}

          {post.type.shouldHaveAdvice && (post.advice || post.adviceRich) && (
            <>
              <Divider margin={"medium"} />
              <JText
                labelKey="post.advices"
                isBold
                sizeName={"largeLow"}
                titleLevel={3}
              />

              {post.adviceRich ? (
                <JHtml
                  source={post?.adviceRich}
                  style={styles.richText}
                  //    contentWidth={width}
                />
              ) : (
                contentsAdvice?.map((content: string, key: number) => (
                  <View key={`advice-${key}`}>
                    <JText
                      label={content}
                      sizeName={"mediumLarge"}
                      style={styles.description}
                    />
                  </View>
                ))
              )}
            </>
          )}

          {Boolean(
            post.type.shouldHaveAction && (post.action || post.actionRich)
          ) && (
            <>
              <Divider margin={"medium"} />

              <JText
                labelKey="post.actions"
                isBold
                sizeName={"largeLow"}
                titleLevel={3}
              />
              {post.actionRich ? (
                <JHtml
                  source={post?.actionRich}
                  style={styles.richText}
                  //    contentWidth={width}
                />
              ) : (
                contentsAction?.map((content: string, key: number) => (
                  <View key={`action-${key}`}>
                    <JText
                      label={content}
                      sizeName={"mediumLarge"}
                      style={styles.description}
                    />
                  </View>
                ))
              )}
            </>
          )}
          <Divider margin={"medium"} />
          <View style={styles.actions}>
            {post.publishedDate ? (
              <View style={styles.time}>
                <Icon
                  name={"clock"}
                  type={"MaterialCommunity"}
                  colorName={"info"}
                />
                <JText
                  label={dayjs(post.publishedDate).locale("fr").fromNow()}
                  fontWeight={"300"}
                  sizeName={"mediumLarge"}
                  isItalic
                  style={styles.timeLabel}
                />
              </View>
            ) : (
              <View />
            )}

            <View style={styles.actionsContainer}>
              {(post.thanks || 0) > 0 && (
                <JText
                  label={`${post.thanks}`}
                  fontWeight={"300"}
                  sizeName={"mediumLarge"}
                  style={styles.thanksCount}
                />
              )}

              {isLikeUpdating ? (
                <ActivityIndicator style={styles.likeSpinner} />
              ) : (
                <Clickable
                  onPress={onLike}
                  style={hasLiked ? styles.downThanks : styles.upThanks}
                >
                  <Icon
                    name={hasLiked ? "like1" : "like2"}
                    type={"AntDesign"}
                    colorName={hasLiked ? "infoLight" : "infoMedium"}
                  />
                </Clickable>
              )}
            </View>
          </View>
        </View>
      </BoxedLayout>
    </Scrollable>
  );
};
