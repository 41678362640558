import React, { useCallback, useEffect, useState } from "react";
import { useUser } from "hooks";
import { Site } from "types/Site";
import { ActiveNotificationsContent } from "components/modals/contents/ActiveNotificationsContent";
import { GenericModal } from "components/modals/GenericModal";
import { Platform } from "react-native";
import useNotification from "providers/NotificationProvider";
import { useTranslation } from "react-i18next";
import { Clickable, Icon, JText } from "components/jlib";
import useTheme from "../../../theme/ThemeProvider";
import fnStyles from "./FollowSwitchStyle";
import { AlsoFollowParentSite } from "components/modals/contents/AlsoFollowParentSite";
import { AlsoFollowChildrenSites } from "components/modals/contents/AlsoFollowChildrenSites";

type Props = {
  site: Site;
  navigation: any;
  expliciteSite?: boolean;
  listPage?: boolean;
  canFollowParent?: boolean;
};

export const FollowSwitch: React.FC<Props> = ({
  site,
  navigation,
  expliciteSite = false,
  listPage = false,
  canFollowParent = false,
}: Props) => {
  const {
    followedSites,
    updateFollowedSites,
    displayCtaWarning,
    setDisplayCtaWarning,
    user,
  } = useUser();
  const { registerForPushNotifications } = useNotification();
  const [isFurtherNotifyModalOpen, setIsFurtherNotifyModalOpen] =
    useState(false);
  const [isFollowParentModalOpen, setIsFollowParentModalOpen] = useState(false);
  const [isFollowChildrenModalOpen, setIsFollowChildrenModalOpen] =
    useState(false);
  const [tmpFollowedSites, setTmpFollowedSites] = useState(followedSites);
  const { theme } = useTheme();
  const styles = fnStyles(theme, listPage);

  const { t } = useTranslation();

  useEffect(() => {
    setTmpFollowedSites(followedSites);
  }, [followedSites]);

  const updateTmpFollowedSites = (value: boolean) => {
    if (value) {
      const newFollowedSites = [...followedSites];
      newFollowedSites.push(Number(site.id));
      setTmpFollowedSites(newFollowedSites);
    } else {
      const newFollowedSites = followedSites.filter(
        (f: number) => f !== Number(site.id)
      );
      setTmpFollowedSites(newFollowedSites);
    }
  };

  const onFollowParentConfirm = useCallback(() => {
    if (site?.parent?.id) {
      updateFollowedSites(site?.parent);
    }
    setIsFollowParentModalOpen(false);
  }, [site, updateFollowedSites]);

  const onFollowChildrenConfirm = useCallback(() => {
    if (site?.children?.length && site?.children?.length > 0) {
      const tmpFollowed: Site[] = [];
      site.children?.forEach((child) => {
        if (followedSites?.find((fS) => fS === child.id) === undefined) {
          tmpFollowed.push(child);
        }
      });
      if (tmpFollowed.length > 0) {
        updateFollowedSites(tmpFollowed);
      }
    }
    setIsFollowChildrenModalOpen(false);
  }, [site.children, updateFollowedSites, followedSites]);

  let displayFurtherNotifyModal = false;

  if (Platform.OS === "web" && !user?.id) {
    displayFurtherNotifyModal = true;
  }

  const onNotificationsConfirm = async () => {
    if (Platform.OS === "web") {
      setIsFurtherNotifyModalOpen(false);
      setDisplayCtaWarning(false);
      navigation?.reset({
        index: 0,
        routes: [
          {
            name: "params",
            state: {
              routes: [{ name: "signUp" }],
            },
          },
        ],
      });
    } else {
      registerForPushNotifications();
    }
  };

  const followValue = Boolean(tmpFollowedSites.find((id) => site?.id === id));

  return (
    <>
      <Clickable
        style={[
          styles.badge,
          followValue
            ? {
                backgroundColor: theme.colors.infoMedium,
              }
            : null,
        ]}
        onPress={() => {
          if (
            canFollowParent &&
            !followValue && // setting follow to true
            site.parent &&
            !tmpFollowedSites.find((id) => id === site?.parent?.id)
          ) {
            setIsFollowParentModalOpen(true);
          } else if (
            !followValue &&
            site.children &&
            site.children?.length > 0 &&
            !tmpFollowedSites.find((id) => id === site?.id)
          ) {
            setIsFollowChildrenModalOpen(true);
          } else if (displayCtaWarning && !followValue) {
            setIsFurtherNotifyModalOpen(true);
          }
          updateTmpFollowedSites(followValue);
          updateFollowedSites(site);
        }}
        ariaSelected={followValue}
      >
        {followValue ? (
          <Icon
            name="check"
            type="Entypo"
            colorName="white"
            style={styles.badgeIcon}
            sizeName="mediumLarge"
          />
        ) : null}
        <JText
          label={
            followValue
              ? expliciteSite
                ? t("labels.unfollowSiteExplicite", { name: site.name })
                : t("labels.unfollowSite")
              : expliciteSite
              ? t("labels.followSiteExplicite", { name: site.name })
              : t("labels.followSite")
          }
          sizeName={listPage ? "average" : "mediumLarge"}
          colorName="white"
        />
      </Clickable>
      {displayFurtherNotifyModal ? (
        <GenericModal
          modalVisible={isFurtherNotifyModalOpen}
          setModalVisible={setIsFurtherNotifyModalOpen}
          ContentComponent={ActiveNotificationsContent}
          confirmLabelKey={"modal.confirmNotificationsLabel"}
          cancelLabelKey={"modal.cancelNotificationsLabel"}
          handleCancel={() => {
            setIsFurtherNotifyModalOpen(false);
            setDisplayCtaWarning(false);
          }}
          handleConfirm={onNotificationsConfirm}
        />
      ) : null}
      <GenericModal
        modalVisible={isFollowParentModalOpen}
        setModalVisible={setIsFollowParentModalOpen}
        ContentComponent={AlsoFollowParentSite}
        confirmLabelKey={"modal.confirmLabel"}
        cancelLabelKey={"modal.cancelLabel"}
        handleCancel={() => {
          setIsFollowParentModalOpen(false);
        }}
        handleConfirm={onFollowParentConfirm}
      />
      <GenericModal
        modalVisible={isFollowChildrenModalOpen}
        setModalVisible={setIsFollowChildrenModalOpen}
        ContentComponent={AlsoFollowChildrenSites}
        confirmLabelKey={"modal.confirmLabel"}
        cancelLabelKey={"modal.cancelLabel"}
        handleCancel={() => {
          setIsFollowChildrenModalOpen(false);
        }}
        handleConfirm={onFollowChildrenConfirm}
      />
    </>
  );
};

export default FollowSwitch;
