import { MaterialTopTabBarProps } from "@react-navigation/material-top-tabs";
import { BoxedLayout } from "components/common/layout/BoxedLayout";
import { isTopBarMode } from "components/utils/deviceMode";
import { useUser } from "hooks";
import React from "react";
import { useTranslation } from "react-i18next";
import { TouchableOpacity, View, useWindowDimensions } from "react-native";
import useTheme from "../../../theme/ThemeProvider";
import { JText } from "../text";
import fnStyles from "./TopNavigationBarStyle";
import { Clickable } from "../clickable";
import { Icon } from "../icon";
import { Spacer } from "../spacer";

type CustomTopBar = MaterialTopTabBarProps & {
  low?: boolean;
  averageLow?: boolean;
  TopComponent?: any;
  BottomComponent?: any;
};

export const TopNavigationBar: React.FC<CustomTopBar> = ({
  state,
  descriptors,
  navigation,
  low = false,
  averageLow = false,
  TopComponent,
  BottomComponent,
}: CustomTopBar) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { checkNavShow } = useUser();

  const { width, height } = useWindowDimensions();

  const isTopBar = isTopBarMode({ width, height });
  const styles = fnStyles(
    theme,
    width,
    isTopBar,
    checkNavShow(navigation.getState()),
    low,
    averageLow
  );

  return isTopBar || state.routes?.length > 1 || !!TopComponent ? (
    <View
      style={TopComponent ? styles.containerCustom : styles.containerClassic}
      role="listitem"
    >
      <BoxedLayout style={styles.boxLayout}>
        {!!TopComponent && <TopComponent />}

        {/* main */}
        <View
          style={{
            ...styles.main,
            position: TopComponent ? "relative" : "absolute",
          }}
        >
          {state.routes?.length > 1 && isTopBarMode({ width, height }) && (
            <View>
              <Clickable
                style={[styles.item, styles.backButton]}
                onPress={() => {
                  navigation.goBack();
                }}
              >
                <Icon type="MaterialCommunity" name="arrow-left" />
                <JText labelKey="auth.back" sizeName="mediumLarge" />
              </Clickable>
            </View>
          )}

          {state.routes?.length > 1 &&
            state.routes.map((route, index) => {
              const { options } = descriptors[route.key];
              const label = t(`header.${route.name}`); // TODO get the traduction of the route name

              const isFocused = state.index === index;

              const onPress = () => {
                const event = navigation.emit({
                  type: "tabPress",
                  target: route.key,
                  canPreventDefault: true,
                });

                if (!isFocused && !event.defaultPrevented) {
                  // The `merge: true` option makes sure that the params inside the tab screen are preserved
                  navigation.navigate(route.name);
                }
              };

              const onLongPress = () => {
                navigation.emit({
                  type: "tabLongPress",
                  target: route.key,
                });
              };

              return (
                <TouchableOpacity
                  key={index}
                  onPress={onPress}
                  onLongPress={onLongPress}
                  accessibilityRole="menuitem"
                  accessibilityState={isFocused ? { selected: true } : {}}
                  accessibilityLabel={options.tabBarAccessibilityLabel}
                  testID={options.tabBarTestID}
                  style={[styles.item, isFocused && styles.focusedItem]}
                >
                  <JText
                    colorName={isFocused ? "tabActiveText" : undefined}
                    label={label}
                    isBold
                    sizeName={"mediumLarge"}
                  />
                </TouchableOpacity>
              );
            })}
        </View>

        {!!BottomComponent && (
          <>
            <Spacer height="mediumLarge" />
            <BottomComponent />
          </>
        )}
      </BoxedLayout>
    </View>
  ) : (
    <></>
  );
};
