import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import Constants from "expo-constants";
import { AppState, AppStateStatus, Platform } from "react-native";

import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import Toast from "react-native-toast-message";

const NotificationContext = createContext<{
  pushToken?: string;
  registerForPushNotifications?: any;
}>({});

type Props = {
  children?: React.ReactNode;
  registerForPushNotifications: () => void;
};

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

export const NotificationProvider = ({ children }: Props) => {
  const [pushToken, setPushToken] = useState<string | undefined>();
  const [appState, setAppState] = useState(AppState.currentState);
  useEffect(() => {
    const handleAppStateChange = async (nextAppState: AppStateStatus) => {
      if (nextAppState === "active" && Platform.OS === "android") {
        await Notifications.dismissAllNotificationsAsync();
      }
      setAppState(nextAppState);
    };

    const handleNotification = (notification: Notifications.Notification) => {
      // for now dismiss all notification if the app is foregrounded
      if (appState === "active") {
        Notifications.dismissNotificationAsync(notification.request.identifier);
        Toast.show({
          type: "success",
          text1: notification.request.content.title ?? "",
          text2: notification.request.content.body ?? "",
        });
      }
    };

    const subscription = AppState.addEventListener(
      "change",
      handleAppStateChange
    );

    const notificationSubscription =
      Notifications.addNotificationReceivedListener(handleNotification);

    return () => {
      subscription.remove();
      notificationSubscription.remove();
    };
  }, [appState]);

  const registerForPushNotifications = useCallback(async () => {
    if (Platform.OS === "web") return;

    if (Platform.OS === "android") {
      await Notifications.setNotificationChannelAsync("default", {
        name: "default",
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: "#FF231F7C",
      });
    }

    if (Device.isDevice) {
      const { status: existingStatus } =
        await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== "granted") {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== "granted") {
        //alert("Failed to get push token for push notification!");
        return;
      }
      try {
        let token = (
          await Notifications.getExpoPushTokenAsync({
            projectId: Constants.expoConfig?.extra?.eas.projectId,
          })
        ).data;
        // console.info({ token });
        setPushToken(token);
      } catch (e) {
        alert(e);
      }
    } else {
      // eslint-disable-next-line no-alert
      alert("Must use physical device for Push Notifications");
    }
  }, []);

  useEffect(() => {
    registerForPushNotifications();
  }, [registerForPushNotifications]);

  const data = useMemo(
    () => ({ pushToken, registerForPushNotifications }),
    [pushToken, registerForPushNotifications]
  );

  return (
    <NotificationContext.Provider value={data}>
      {children}
    </NotificationContext.Provider>
  );
};

const useNotification = () => useContext(NotificationContext);

export default useNotification;
