import React from "react";
import {
  View,
  Modal,
  useWindowDimensions,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import useTheme from "../../theme/ThemeProvider";
import { SubscribeContent } from "./contents/SubscribeContent";
import fnStyles from "./ModalStyle";
import { Button, Clickable, JText, Spacer } from "../jlib";
import { useTranslation } from "react-i18next";

type Props = {
  modalVisible: boolean;
  setModalVisible: any;
  navigation: any;
  descriptionKey?: string;
};

export const SubscribeModal: React.FC<Props> = ({
  modalVisible,
  setModalVisible,
  navigation,
  descriptionKey,
}) => {
  const { theme } = useTheme();
  const { height, width } = useWindowDimensions();

  const styles = fnStyles(theme, height, width);
  const { t } = useTranslation();

  return (
    <Modal
      visible={modalVisible}
      animationType="fade"
      hardwareAccelerated
      transparent
    >
      <TouchableOpacity
        onPress={() => {
          setModalVisible(false);
        }}
        style={styles.layerStyle}
        accessibilityRole="button"
        accessibilityLabel={t("titles.imageInModalUntitled")}
      >
        <View style={styles.panelStyle}>
          <ScrollView>
            <SubscribeContent descriptionKey={descriptionKey} />

            <View style={styles.panelFooterStyle}>
              <Button
                onPress={() => {
                  navigation?.reset({
                    index: 0,
                    routes: [
                      {
                        name: "params",
                        state: {
                          routes: [{ name: "signUp" }],
                        },
                      },
                    ],
                  });

                  setModalVisible(false);
                }}
                labelKey={"modal.subscribe"}
                type={"gradient"}
              />
              <Spacer height="mediumLarge" />
              <Clickable onPress={() => setModalVisible(false)}>
                <JText
                  labelKey={"modal.skipSubscribeLabel"}
                  colorName={"emphasisText"}
                  isBold
                  centered
                />
              </Clickable>
            </View>
          </ScrollView>
        </View>
      </TouchableOpacity>
    </Modal>
  );
};
