import { BoxedLayout } from "components/common/layout/BoxedLayout";
import { isTopBarMode } from "components/utils/deviceMode";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  FlatList,
  ImageBackground,
  Platform,
  View,
  useWindowDimensions,
} from "react-native";
import {
  Button,
  Card,
  ForegroundButton,
  JText,
  SView,
  SiteCard,
  Spacer,
} from "../../../components";
import useTheme from "../../../theme/ThemeProvider";
import useSites from "hooks/useSites";
import useConfiguration from "../../../configuration/ConfigurationProvider";
import getWording from "utils/wording";
import fnStyles from "../SitesHomeScreenStyle";
import { getFirstInSiteType } from "utils/getFirstInSiteType";
import { useTranslation } from "react-i18next";
import { Site } from "types/Site";
import { useUser } from "hooks";
import { SavePolygonModal } from "components/modals/SavePolygonModal";

type Props = {
  navigation: any;
};

const SiteToSuggest = ({ navigation }: any) => {
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const styles = fnStyles(theme, isTopBarMode({ width, height }));
  const { configuration } = useConfiguration();

  return (
    <View style={styles.siteSuggest}>
      <Card style={styles.cardSiteSuggest}>
        <ImageBackground
          source={theme.images.pictoSearch}
          style={styles.siteSuggestImage}
        />
        <JText
          labelKey="site.unexist"
          valuesKey={{ wording: getWording(configuration).singular }}
          sizeName="mediumLarge"
          centered
        />

        <Button
          labelKey="site.unexistAction"
          type="gradient"
          onPress={() => {
            navigation.navigate("siteSuggestion");
          }}
        />
      </Card>
    </View>
  );
};

const RenderItem = memo(
  ({ item, navigation, highlightedSiteId, items }: any) => {
    const { theme } = useTheme();
    const { configuration } = useConfiguration();

    const displayCat =
      configuration.sites.display.typesSeparator &&
      getFirstInSiteType(items, item);

    return (
      <BoxedLayout key={`site-${item.id}`}>
        {displayCat && (
          <View style={{ padding: theme.sizings.medium }}>
            <JText labelKey={`siteTypes.${item?.siteType?.name}`} isBold />
          </View>
        )}
        <SiteCard
          key={item.id}
          item={item}
          navigation={navigation}
          isHighlighted={highlightedSiteId === item.id}
          titleLevel={2}
          id={`s${item.id}`}
        />
      </BoxedLayout>
    );
  }
);

export const SectionList: React.FC<Props> = ({ navigation }) => {
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const isTopBar = isTopBarMode({ width, height });
  const styles = fnStyles(theme, isTopBarMode({ width, height }));
  const { sites, sortAndFilterSites, highlightedSiteId, mapPolygonFilter } =
    useSites();
  const { followedSites, updateFollowedSites } = useUser();
  const { configuration } = useConfiguration();
  const [scrolledTo, setScrolledTo] = useState<number | null>(null);
  const { t } = useTranslation();
  const [isSavePolygonModalVisible, setIsSavePolygonModalVisible] =
    useState(false);

  const showSuggestion = configuration.sites.features.suggestionForm;

  const orderedItems = useMemo(
    () =>
      sortAndFilterSites(sites, {
        filterOnShownInApp: true,
        filterOnUseMapPolygon: true,
      }),
    [sites, sortAndFilterSites]
  );

  const unfollowedSites = useMemo(() => {
    const tmpFollowed: Site[] = [];
    orderedItems?.forEach((s) => {
      if (followedSites?.find((fS) => fS === s.id) === undefined) {
        tmpFollowed.push(s);
      }
    });
    return tmpFollowed;
  }, [followedSites, orderedItems]);

  const showSavePolygonModal = () => {
    setIsSavePolygonModalVisible(true);
  };

  const handleFollowAllPoints = useCallback(() => {
    if (
      orderedItems?.length &&
      orderedItems?.length > 0 &&
      unfollowedSites?.length &&
      unfollowedSites?.length > 0
    ) {
      updateFollowedSites(unfollowedSites);
      showSavePolygonModal();
    }
  }, [orderedItems, unfollowedSites, updateFollowedSites]);

  const spacer = useCallback(() => {
    return <Spacer height={"smallMedium"} backgroundColor={"transparent"} />;
  }, []);

  useEffect(() => {
    if (
      Platform.OS === "web" &&
      orderedItems &&
      highlightedSiteId &&
      scrolledTo !== highlightedSiteId
    ) {
      const section = document.querySelector(`#s${highlightedSiteId}`);
      section?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });

      setScrolledTo(Number(highlightedSiteId));
    }
  }, [highlightedSiteId, orderedItems, scrolledTo, theme]);

  return (
    <>
      <SView>
        {mapPolygonFilter?.length && orderedItems?.length ? (
          <BoxedLayout
            style={{
              marginTop: isTopBar ? theme.normalize(20) : theme.normalize(100),
              marginBottom: theme.normalize(20),
              paddingLeft: theme.normalize(16),
            }}
          >
            <JText label="Dans la zone sélectionnée" />
          </BoxedLayout>
        ) : null}
        {orderedItems?.length ? (
          <FlatList
            data={orderedItems}
            renderItem={({ item, index }) => (
              <>
                <RenderItem
                  item={item}
                  navigation={navigation}
                  highlightedSiteId={highlightedSiteId}
                  items={orderedItems}
                />

                {index + 1 === orderedItems?.length && showSuggestion && (
                  <BoxedLayout>
                    <SiteToSuggest navigation={navigation} />
                  </BoxedLayout>
                )}
              </>
            )}
            contentContainerStyle={[
              styles.flatList,
              {
                marginTop: isTopBar
                  ? mapPolygonFilter?.length
                    ? theme.normalize(0)
                    : theme.sizings.medium
                  : mapPolygonFilter?.length
                  ? 0
                  : theme.normalize(110),
              },
            ]}
            ItemSeparatorComponent={spacer}
          />
        ) : (
          <BoxedLayout>
            <View style={styles.singleSiteSuggest}>
              <SiteToSuggest navigation={navigation} />
            </View>
          </BoxedLayout>
        )}
      </SView>
      {mapPolygonFilter?.length && unfollowedSites?.length ? (
        <ForegroundButton
          handleButtonPressed={handleFollowAllPoints}
          buttonLabel={t("site.followAllSelectedSites")}
          iconName="pin"
          iconType="MaterialCommunity"
          bottomBar={!isTopBar}
          spanHorizontal
        />
      ) : null}
      <SavePolygonModal
        modalVisible={isSavePolygonModalVisible}
        setModalVisible={setIsSavePolygonModalVisible}
      />
    </>
  );
};
