import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme) =>
  StyleSheet.create({
    container: {
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      paddingBottom: theme.normalize(100),
      paddingTop: theme.normalize(30),
    },
    text: {
      width: "80%",
      alignSelf: "center",
      marginTop: theme.normalize(-30),
    },
  });
