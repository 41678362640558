import React from "react";
import { StackScreenProps } from "@react-navigation/stack";
import { SitesParamsList } from "../../navigation";
import { Scrollable, TimeLine } from "../../components";
import useTheme from "../../theme/ThemeProvider";
import { BoxedLayout } from "components/common/layout/BoxedLayout";
import { isTopBarMode } from "components/utils/deviceMode";
import useSites from "hooks/useSites";
import { useWindowDimensions } from "react-native";

type Props = StackScreenProps<SitesParamsList, "siteRoadmap">;

export const SiteRoadmapScreen: React.FC<Props> = () => {
  const { theme } = useTheme();
  const { site } = useSites();

  const { width, height } = useWindowDimensions();

  return (
    <Scrollable
      additionalProps={{
        contentContainerStyle: {
          paddingTop: theme.normalize(110),
          paddingHorizontal: theme.normalize(20),
        },
      }}
    >
      <BoxedLayout
        style={{
          backgroundColor: theme.colors.white,
          padding: isTopBarMode({ width, height })
            ? theme.sizings.medium
            : theme.normalize(theme.sizings.smallMedium),
          borderRadius: theme.radius.medium,
        }}
      >
        <TimeLine keySteps={site?.steps ?? []} />
      </BoxedLayout>
    </Scrollable>
  );
};
