import { isTopBarMode } from "../../../components/utils/deviceMode";
import React from "react";
import { View, useWindowDimensions } from "react-native";
import { JText } from "../../../components";
import useTheme from "../../../theme/ThemeProvider";
import fnStyles from "./../ParamsScreenStyle";

import LocationHelper from "components/jlib/locationHelper/LocationHelper";

const AccountLocationBlock = () => {
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const styles = fnStyles(theme, isTopBarMode({ width, height }));

  return (
    <View>
      <JText
        labelKey="site.currentLocationSaved"
        isBold
        centered
        style={styles.positionSectionLabel}
        titleLevel={2}
      />
      <LocationHelper disableScroll />
    </View>
  );
};

export default AccountLocationBlock;
