import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme, isTopBarMode: boolean) =>
  StyleSheet.create({
    container: {
      paddingVertical: 0,
      alignItems: "center",
      justifyContent: "center",
    },
    contentContainer: {
      width: isTopBarMode ? "70%" : "100%",
      marginVertical: theme.normalize(theme.sizings.medium),
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: "row",
    },
    main: {
      marginRight: theme.sizings.larger,
      flex: 1,
    },
    divider: {
      width: "70%",
    },
  });
