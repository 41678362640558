import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme, isTopBarMode: boolean) =>
  StyleSheet.create({
    container: {
      flexDirection: isTopBarMode ? "row" : "column",
      marginTop: isTopBarMode ? theme.normalize(-46) : theme.normalize(-30),
      marginBottom: theme.normalize(-46),
    },
  });
